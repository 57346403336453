import { NotificationManager, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import moment from "moment";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { THANK_YOU_NOTE_EXPLORER_URL } from "../../constants";
import { formatDate } from "../../utils/formatting";

const NotificationContainer = () => {
  const { getNotificationsRequest, markAsReadRequest, markAllAsRead } = useStoreActions(
    (actions) => actions.notifications
  );
  const { showModal } = useStoreActions((actions) => actions.modals);
  const { notifications } = useStoreState((state) => state.notifications);
  const { user } = useStoreState((state) => state.user);

  const navigate = useNavigate();

  const dismiss = (event) => {
    event.preventDefault();
    const { id } = event.currentTarget.dataset;

    markAsReadRequest(id).then(getNotificationsRequest);
  };

  const onClick = (event) => {
    event.preventDefault();
    const config = notifications.find(({ id }) => id == event.currentTarget.dataset.id).config;
    const notificationType = config.type;
    const auditType = config.type === "user_restricted" ? "restricted" : "flagged";

    switch (notificationType) {
      case "reward_received":
        showModal({
          modalName: "RewardFoundModal",
          reward_amount: config.reward_amount,
          name: config.contribution_name,
          title: "Congratulations! Crypto received!",
          community_id: config.community_id,
        });
        break;
      case "thrive_token_setup":
        showModal({ modalName: "ConnectSocialMediasModal" });
        break;
      case "pending_contribution_created":
        const {
          community_slug,
          claimer_verification_code,
          contribution_claim_id,
          contribution_claim_activity_id,
          contribution_claim_message,
        } = config;
        if (claimer_verification_code) {
          showModal({
            modalName: "ManagePendingContributionModal",
            isAccepting: true,
            id: contribution_claim_activity_id,
            contribution_claim_id,
            message: contribution_claim_message,
            verification_code: claimer_verification_code,
            ...config,
          });
        }
        const url = `/communities/${community_slug}?tabindex=5`;
        navigate(url);
        break;
      case "reward_receipt_created":
        window.open(`${THANK_YOU_NOTE_EXPLORER_URL}/${config.receipt_tx_id}`);
        break;
      case "season_earnings_claim_reminder":
        let seasonIdentifier = config.season_slug || config.season_id;
        navigate(`/seasons/${seasonIdentifier}`);
      case "update_profile_info":
        showModal({ modalName: "UpdateProfileFlowModal" });
        break;
      case "user_restricted":
        showModal({ modalName: "MoreDetailsModal", type: auditType, user });
        break;
    }
  };

  const transformedNotifications = notifications
    .filter(filterKnownNotification)
    .map((notification) => addRenderProp(notification));

  useEffect(() => {
    getNotificationsRequest();
  }, []);

  return (
    <NotificationManager
      dismiss={dismiss}
      notificationClick={onClick}
      readAll={markAllAsRead}
      notifications={transformedNotifications}
    />
  );
};

const ICON_BY_TYPE = {
  reward_received: { icon: "trophy-star" },
  fully_completed: { icon: "filled-check-circle" },
  thrive_token_setup: { icon: "alert-circle", iconColor: "error", iconWidth: "20px" },
  pending_contribution_created: { icon: "alert-circle", iconColor: "green", iconWidth: "20px" },
  reward_receipt_created: { icon: "filled-check-circle", iconColor: "#8DCF9F", iconWidth: "16px", iconHeight: "18px" },
  season_earnings_claim_reminder: { icon: "coins", iconWidth: "20px" },
  update_profile_info: { icon: "filled-check-circle", iconColor: "#8DCF9F", iconWidth: "16px" },
  user_restricted: { icon: "hand2", iconColor: "error", iconWidth: "16px" },
  user_flagged: { icon: "warning2", iconColor: "error", iconWidth: "16px" },
};

const TITLE_BY_TYPE = {
  reward_received: "Contribution rewarded!",
  fully_completed: "Contribution fully completed!",
  thrive_token_setup: "See your ThriveCoin in MetaMask",
  reward_receipt_created: "Contribution fully completed!",
};

const handledNotifications = Object.keys(ICON_BY_TYPE);

const filterKnownNotification = ({ config }) => handledNotifications.includes(config.type);

const addRenderProp = (notification) => {
  return {
    ...notification,
    text: <TextProp notification={notification} />,
    time: moment(notification.created_at).fromNow(),
    ...ICON_BY_TYPE[notification.config.type],
    title: notification.title || TITLE_BY_TYPE[notification.config.type],
  };
};

const RewardReceiptCreatedContent = ({ id, community_name, contribution_name }) => {
  return (
    <>
      <Text typography="body/medium" bold maxWidth="325px">
        Congratulations! {community_name} recently thanked you for your contribution "{contribution_name}".
      </Text>
      <Text extraBold color="link" data-id={id} inline>
        Click Here To See Your Thank You Note!
      </Text>
    </>
  );
};

const SeasonEarningsClaimContent = ({ id, community_name, season_order, claiming_deadline }) => {
  const date = formatDate(claiming_deadline);

  return (
    <Text typography="body/medium" bold maxWidth="325px">
      Claim your earnings from {community_name} Season {season_order}. If not claimed before {date} your earnings will
      be auto-donated.
      <Text extraBold color="link" data-id={id} inline spacing="ml-1">
        Click here to claim
      </Text>
    </Text>
  );
};

const ContributionRejectedContent = ({ description }) => {
  return (
    <>
      <Text typography="body/medium" bold maxWidth="325px">
        {description}
      </Text>
    </>
  );
};

const UpdateProfileInfoContent = () => {
  return (
    <Text typography="body/medium" bold maxWidth="325px">
      <Text extraBold color="link" inline>
        Click here
      </Text>{" "}
      to set a custom username and profile image.
    </Text>
  );
};

const Audits = ({ id }) => {
  return (
    <>
      <Text typography="body/medium" regular>
        The ThriveCoin Admin has restricted your Account.
      </Text>
      <Text extraBold color="link" data-id={id}>
        Click here for more details
      </Text>
    </>
  );
};

const TextProp = ({ notification }) => {
  const { description, config, id } = notification;
  const makeFooter = (text) => (
    <Text color="link" data-id={id} inline>
      {text}
    </Text>
  );

  if (config.type == "reward_received" || config.type == "fully_completed") {
    return (
      <>
        <Text typography="body/medium" bold maxWidth="325px">
          Congratulations! You recently recieved another <b>{parseFloat(config.reward_amount)} $THRIVE</b> for your
          contribution “{config.contribution_name}”.{" "}
          <Text color="link" data-id={id} inline>
            {makeFooter(" Click to celebrate!")}
          </Text>
        </Text>
      </>
    );
  }

  if (config.type == "thrive_token_setup") {
    return (
      <>
        <Text typography="Body/Medium" bold spacing="mt-2">
          To set up your MetaMask to display $THRIVE tokens,{" "}
          <Text color="link" data-id={id} bold inline>
            {makeFooter(" Click Here to View!")}
          </Text>
        </Text>
      </>
    );
  }

  if (config.type == "pending_contribution_created") {
    return (
      <Text typography="body/medium" semiBold color="01Primary700" spacing="mt-2">
        {description}
        <Text color="link" data-id={id} bold inline>
          {makeFooter(" Click Here to View!")}
        </Text>
      </Text>
    );
  }

  if (config.type == "reward_receipt_created") {
    return <RewardReceiptCreatedContent id={id} {...config} />;
  }

  if (config.type == "season_earnings_claim_reminder") {
    return <SeasonEarningsClaimContent id={id} {...config} />;
  }

  if (config.type == "update_profile_info") {
    return <UpdateProfileInfoContent id={id} {...config} />;
  }

  if (config.type == "contribution_claim_rejected") {
    return <ContributionRejectedContent id={id} description={description} {...config} />;
  }

  if (config.type == "user_restricted") {
    return <Audits id={id} description={description} {...config} />;
  }

  if (config.type == "user_flagged") {
    return (
      <Text typography="body/medium" regular>
        The ThriveCoin Admin has flagged your User account.
      </Text>
    );
  }

  return <></>;
};

export default NotificationContainer;
