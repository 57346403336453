import { action, thunk } from "easy-peasy";
import HttpWrapper from "../httpRequester";

const requester = new HttpWrapper("users");
const communityRequester = new HttpWrapper("communities");

//TODO: Either remove this or rename this to contributions

const PAGINATION = {
  page: 1,
  total: 1,
  per_page: 20,
};

const attributes = {
  stats: null,
  rewards: [],
  community: [],
  communityPagination: [],
  communityRewards: [],
  communityRewardsPagination: PAGINATION,
};

const actions = {
  getRewards: thunk(({ setRewards }, { keyword = "", sort_type = "date", sort_direction = "desc" }) => {
    const params = { sort_type, sort_direction };
    if (keyword) {
      params.keyword = keyword;
    }
    return requester.get("/current/rewards", params).then(({ rewards }) => {
      setRewards(rewards);
    });
  }),
  getStatsRequest: thunk(({ setStats }) => {
    return requester.get("/current/stats").then(({ stats }) => {
      setStats(stats);
    });
  }),
  setRewards: action((state, rewards) => {
    state.rewards = rewards;
  }),
  setStats: action((state, stats) => {
    state.stats = stats;
  }),
  // Community
  getCommunityRewards: thunk(
    (
      { setCommunityRewards, loadMoreRewardsCommunity, setCommunityRewardsPagination },
      { community_id, sort_type = "date", sort_direction = "desc", keyword = "", season_ids = [], page = 1 }
    ) => {
      const params = { sort_type, sort_direction, season_ids, page };
      if (keyword) {
        params.keyword = keyword;
      }
      return communityRequester.get(`${community_id}/contributions`, params).then(({ contributions, pagination }) => {
        if (page > 1) {
          loadMoreRewardsCommunity(contributions);
        } else {
          setCommunityRewards(contributions);
        }
        setCommunityRewardsPagination(pagination);
      });
    }
  ),
  setCommunityRewards: action((state, communityRewards) => {
    state.communityRewards = communityRewards;
  }),
  loadMoreRewardsCommunity: action((state, communityRewards) => {
    state.communityRewards = [...state.communityRewards, ...communityRewards];
  }),
  setCommunityRewardsPagination: action((state, pagination) => {
    state.communityRewardsPagination = pagination;
  }),
};

const rewards = {
  ...attributes,
  ...actions,
};

export default rewards;
