import { action, thunk } from "easy-peasy";
import HttpWrapper, { deleteToken, readToken, writeToken } from "../httpRequester";

const appRequester = new HttpWrapper("users");
const authRequester = new HttpWrapper("users");
const addressesRequester = new HttpWrapper("addresses");
const membershipInvitationRequester = new HttpWrapper("membership_invitations");
const socialProfileRequester = new HttpWrapper("social_profiles");
const profilesRequester = new HttpWrapper("profiles");

const attributes = {
  userID: undefined,
  onboarding: localStorage.getItem("onboarding"),
  publicUser: { wallets: [] },
};

const actions = {
  setAttribute: action((state, { key, data }) => {
    state[key] = data;
  }),
  getAvailablePercentage: thunk((_, __, { getStoreActions, getState }) => {
    return appRequester.get("/current/available_percentage").then(({ percentage }) => {
      const { updateEntity } = getStoreActions().entities;
      const id = getState().userID;
      const updatedUser = { available_percentage: percentage };
      updateEntity({ id, data: updatedUser, entityName: "user" });
    });
  }),
  getSocialProfiles: thunk(({ getAvailablePercentage }, _, { getState, getStoreActions }) => {
    const url = "current/social_profiles";
    return appRequester
      .get(url)
      .then(({ social_profiles: socialProfiles }) => {
        const { updateEntity } = getStoreActions().entities;
        const id = getState().userID;
        const updatedUser = { socialProfiles };
        updateEntity({ id, data: updatedUser, entityName: "user" });
      })
      .then(getAvailablePercentage);
  }),
  setNonce: action((state, nonce) => {
    state.nonce = nonce;
  }),
  getNonce: thunk(({ setNonce }, address) => {
    const url = `${address}/nonce`;

    return addressesRequester.get(url).then(({ nonce }) => {
      setNonce(nonce);
      return nonce;
    });
  }),
  signatureSignIn: thunk(({ processUser }, { signature, address, referral_code, discord_generated_uid }) => {
    return authRequester
      .post("/create_with_address", { signature, address, referral_code, discord_generated_uid })
      .then((data) => {
        processUser(data);
        return data.user;
      });
  }),
  rejectInvitationRequest: thunk((actions, id) => {
    const url = `${id}/reject`;

    return membershipInvitationRequester.post(url);
  }),
  AcceptInvitationRequest: thunk((actions, id) => {
    const url = `${id}/accept`;

    return membershipInvitationRequester.post(url);
  }),
  userGroupInvitesRequest: thunk(() => {
    return appRequester.get("pending_invitations").then((data) => data.invitations);
  }),
  userSearch: thunk((actions, keyword) => {
    const url = "search";

    return appRequester.post(url, { keyword }).then((data) => data.profiles);
  }),
  logout: thunk(({ setUserID }) => {
    setUserID();
    const isLogged = readToken();
    if (!isLogged) return Promise.resolve();

    return appRequester.delete("/current/logout").then(deleteToken);
  }),
  setAuthLoading: action((state, value) => {
    state.authLoading = value;
  }),
  createAccountRequest: thunk(({ processUser }, payload) => {
    return authRequester.create({ user: payload }).then(processUser);
  }),
  processUser: thunk(({ getProfileRequest, setUserID, sessionChecked }, { user }, { getStoreActions }) => {
    const { normalizeCollection } = getStoreActions().entities;
    writeToken(user.authentication_token);
    sessionChecked(true);
    delete user.username;
    normalizeCollection({ data: [user], entityName: "user" });
    setUserID(user.id);

    return getProfileRequest(user.id);
  }),
  setUserID: action((state, userID) => {
    state.userID = userID;
  }),
  getProfileRequest: thunk(({ getSocialProfiles }, _, { getStoreActions }) => {
    return appRequester
      .get()
      .then(({ profile }) => {
        const { updateEntity } = getStoreActions().entities;
        const updatedUser = { ...profile, profile_id: profile.id, id: profile.user_id };
        updateEntity({ id: profile.user_id, data: updatedUser, entityName: "user" });
      })
      .then(getSocialProfiles);
  }),
  refreshSocialProfiles: thunk(({ getSocialProfiles }) => {
    return getSocialProfiles();
  }),
  setProfile: action((state, { profile }) => {
    state.profile = profile;
  }),
  getPublicUserRequest: thunk(({ setPublicUser }, user_id) => {
    const url = `/${user_id}`;

    return profilesRequester.get(url).then(({ profile }) => {
      setPublicUser(profile);
    });
  }),
  setPublicUser: action((state, data) => {
    state.publicUser = data;
  }),
  confirmUser: action((state) => {
    state.user.confirmed = true;
  }),
  sessionChecked: action((state, data) => {
    state.sessionHandled = data;
  }),
  setOnboardingStatus: action((state, onboarding) => {
    state.onboarding = onboarding;
  }),
  emailVerificationCode: thunk((actions, email) => {
    return socialProfileRequester.post("/email_verification_code", { email });
  }),
  resendVerificationCode: thunk(() => {
    return socialProfileRequester.post("/resend_verification_code");
  }),
  connectSocialProfile: thunk(({ refreshSocialProfiles }, { provider, params }) => {
    return socialProfileRequester
      .post(`/connect/${provider}?domain=${window.location.origin}`, { ...params })
      .then(refreshSocialProfiles);
  }),
  disconnectSocialProfile: thunk((_, provider) => {
    return socialProfileRequester.delete(`/disconnect/${provider}`);
  }),
  generateProviderUrl: thunk((_, provider) => {
    return socialProfileRequester.get(`/generate_url/${provider}?domain=${window.location.origin}`);
  }),
  restoreSession: thunk(({ processUser, sessionChecked }, token) => {
    return authRequester
      .post("/current")
      .then(processUser)
      .finally(() => sessionChecked(true));
  }),
  refresh: thunk(({ processUser }) => authRequester.post("/current").then(processUser)),
  createWallet: thunk((actions, { address, chain_type }) => {
    const wallet = { address, chain_type };
    return appRequester.post("/create_wallet", { wallet });
  }),
  ////// Auth Api Requests
  verifyUserRequest: thunk(({ confirmUser, restoreSession }, { code, id }) => {
    return authRequester
      .post(`${id}/verify`, { verification_code: code })
      .then(confirmUser)
      .then(() => {
        const token = readToken();

        restoreSession(token);
      });
  }),
  passwordRecoveryRequest: thunk((actions, email) => {
    return authRequester.post("/new_password", { user: { email } });
  }),
  reSendVerificationRequest: thunk((actions, email) => {
    return authRequester.post("/resend_verification", { user: { email } });
  }),
  resetPasswordRequest: thunk((actions, { token, password, passwordConfirm }) => {
    return authRequester.post("/reset_password", {
      user: {
        password,
        reset_password_token: token,
        password_confirmation: passwordConfirm,
      },
    });
  }),
};

const authentication = {
  ...attributes,
  ...actions,
};

export default authentication;
