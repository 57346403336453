import { Box, Loader, Text } from "@thrivecoin/ui-components";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { HeaderBox } from "../ManageCommunities/ManageTabsContainer";
import { AddListingsContext, AddListingsProvider } from "./AddListingsContext";
import BreadCrumb from "./BreadCrumb";
import ListingDropBox from "./ListingDropBox";
import ListingsList from "./ListingsList";

const AddListings = () => {
  const { id } = useParams();
  return (
    <AddListingsProvider id={id}>
      <_AddListings />
    </AddListingsProvider>
  );
};

const _AddListings = () => {
  const {
    season,
    communityListings,
    seasonListings,
    seasonListingsTotal,
    addListingsToSeason,
    dropAddListingToSeason,
    searchCommunitySeasons,
    searchListingSeasons,
    previewListing,
    updateReward,
    statusCounts,
    loading,
  } = useContext(AddListingsContext);
  const { title, community_name, community_id } = season;

  if (loading) {
    return (
      <Box flex justifyContent="center" spacing="my-a">
        <Loader $size="64px" />
      </Box>
    );
  }

  return (
    <Box spacing="pb-8">
      <HeaderBox spacing="py-6+2">
        <Box flex direction="column" gap="14px">
          <BreadCrumb {...season} />
          <Text align="center" typography="Heading/Large" color="01Primary700">
            Manage {title} Ways to Contribute
          </Text>
          <Text align="center" typography="Body/Medium" color="01Primary700">
            You can change the default order of the ways to contribute by dragging and dropping the items bellow
          </Text>
        </Box>
      </HeaderBox>
      <Box flex direction="column" spacing="px-6" gap="32px">
        <ListingDropBox
          title={title}
          listings={seasonListings}
          onDrop={dropAddListingToSeason}
          searchListings={searchListingSeasons}
          previewListing={previewListing}
          seasonListingsTotal={seasonListingsTotal}
          updateReward={updateReward}
        />
        <ListingsList
          statusCounts={statusCounts}
          title={title}
          community_name={community_name}
          community_id={community_id}
          listings={communityListings}
          seasonID={season.id}
          addListingsToSeason={addListingsToSeason}
          searchListings={searchCommunitySeasons}
          previewListing={previewListing}
        />
      </Box>
    </Box>
  );
};

export default AddListings;
