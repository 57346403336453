import { Box, breakpoints, Button, TabsContainer, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import styled from "styled-components";
import { MainContainer } from "../common/MainContainer";
import Steps from "../ListingEditor/Steps";
import ManageTabsContainer from "./ManageTabsContainer";
import BACKGROUND_IMAGE from "@assets/season_background.svg";
import OverviewTab from "./OverviewTab";

const StubComponent = () => "lala";

const tabClasses = [OverviewTab];

// const headers = ["Communities Overview", "Submitted Contributions", "Restricted Users"];
const headers = ["Communities Overview"];

const ManageCommunities = () => {
  const { isThriveAdmin } = useStoreState((state) => state.user);
  const { showModal } = useStoreActions((actions) => actions.modals);
  const createCommunity = () => showModal({ modalName: "CommunityEditorModal" });

  return (
    <TopLevelContainer>
      <HeaderBox bgColor={`url(${BACKGROUND_IMAGE})`}>
        <MainContainer flex alignItems="space-between" justifyContent="space-between">
          <Text color="02Primary0" typography="heading/x-large">
            Manage Communities
          </Text>
          {isThriveAdmin && (
            <Button
              hoverBackground="02Primary0"
              borderColor="02Primary0"
              bgColor="02Primary0"
              color="02Primary500"
              rightIcon="plus"
              borderRadius="40px"
              onClick={createCommunity}
            >
              Add New Community
            </Button>
          )}
        </MainContainer>
      </HeaderBox>
      <TabsContainer tabClasses={tabClasses} headers={headers}></TabsContainer>

    </TopLevelContainer>
  );
};

const TopLevelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SecondLevelContainer = styled.div`
  margin: 0 auto;
  border-radius: 12px 12px 0px 0px;
  background: ${({ theme }) => theme.colors["01Surface"]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

const HeaderBox = styled(Box)`
  padding: 52px 0;
  background-size: cover;
  background-position-y: bottom;

  @media ${breakpoints.mobile} {
    flex-direction: column;
    gap: 24px;
  }
`;

ManageCommunities.Stepper = () => (
  <TopLevelContainer>
    <SecondLevelContainer>
      <Steps />
    </SecondLevelContainer>
  </TopLevelContainer>
);

ManageCommunities.ManageTabsContainer = () => (
  <TopLevelContainer>
    <ManageTabsContainer />
  </TopLevelContainer>
);

export default ManageCommunities;
