import moment from "moment";

export const nthSuffix = (n) => ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";

export const ordinal = (n) => n + nthSuffix(n);

export const formatUsername = (username) => {
  if (username?.length > 20) {
    const leftName = username.substring(0, 5);
    const rightName = username.substring(username.length - 4, username.length);
    return leftName + "..." + rightName;
  }

  return username;
};

export const formatDate = (date) => moment(date).format("MMM DD, YYYY");

const CURRENCY_FORMATTER = new Intl.NumberFormat("en-US");

export const numberWithCommas = (number, defaultValue = 0) => CURRENCY_FORMATTER.format(number || defaultValue);

export const toArray = (value) => (Array.isArray(value) ? value : [value]);
