import { Box, Button, TagFilters, Text } from "@thrivecoin/ui-components";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { STATUS_FILTERS } from "../useManagePage";

const CONFIGS = {
  default: {
    sectionBg: "01Primary100",
    boxBg: "manageCard2",
  },
  upcoming: {
    sectionBg: "01Primary100",
    boxBg: "02Primary500",
  },
  published: {
    sectionBg: "03Primary200",
    boxBg: "03Primary500",
  },
};

const configuration = (status) => CONFIGS[status] || CONFIGS.default;

export const SectionBox = ({ title, children, status = "default" }) => {
  const config = configuration(status);

  return (
    <SectionWrapper spacing="mt-6" bgColor={config.sectionBg}>
      <Box flex alignItems="center" gap="12px" spacing="mb-5">
        <Text typography="heading/small" bold>
          {title}
        </Text>
      </Box>
      {children}
    </SectionWrapper>
  );
};

export const RectangleBox = ({ children, status = "default", lgSize, ...props }) => {
  const config = configuration(status);

  return (
    <RectangleWrapper bgColor={config.boxBg} lgSize={lgSize} {...props}>
      {children}
    </RectangleWrapper>
  );
};

export const CircleBox = ({ status = "default", children, bgColor, ...props }) => {
  const config = configuration(status);

  return (
    <CircleWrapper bgColor={bgColor || config.boxBg} {...props}>
      {children}
    </CircleWrapper>
  );
};

export const EditButton = ({ disabled, ...props }) => {
  const variant = disabled ? "01Primary110" : "01Primary700";

  return (
    <CustomButton
      color={variant}
      variant={variant}
      kind="outline"
      rightIcon="pen"
      height="52px"
      borderRadius="50px"
      isDisabled={disabled}
      {...props}
    />
  );
};

export const StatusFilter = ({ onStatusClick, filters = STATUS_FILTERS, ...props }) => {
  const [tagOptions, setTags] = useState([]);

  const onTagclick = (e) => {
    const { value } = e.currentTarget.dataset;
    const newFilters = structuredClone(filters);
    newFilters.forEach((filter) => (filter.selected = filter.value == value));
    setTags(newFilters);
    onStatusClick(e);
  };

  useEffect(() => {
    setTags(structuredClone(filters));
  }, [filters]);

  return (
    <TagFilters
      width="100%"
      spacing="my-5 py-5 px-4+1"
      bgColor="seasonFilters"
      tagOptions={tagOptions}
      onTagClick={onTagclick}
      {...props}
    />
  );
};

const SectionWrapper = styled(Box)`
  border-radius: 5px;
  flex: 1;
  padding: 16px 14px 20px;
  display: flex;
  flex-direction: column;
`;

const RectangleWrapper = styled(Box)`
  height: ${({ lgSize }) => (lgSize ? "130px" : "56px")};
  border-radius: 8px;
`;

const CircleWrapper = styled(Box)`
  width: 56px;
  height: 56px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomButton = styled(Button)`
  ${({ isDisabled }) =>
    isDisabled &&
    `
    cursor: not-allowed;
    pointer-events: none;
  `}
`;

const Status = styled(Box)`
  padding: 0 32px;
  height: 44px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  white-space: nowrap;

  & > * {
    pointer-events: none;
  }
`;
