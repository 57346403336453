import { action, thunk } from "easy-peasy";
import HttpWrapper from "../httpRequester";
import { normalizeCollection2 } from "../normalizerSchema";

const requester = new HttpWrapper("listing_seasons");
const seasonsRequester = new HttpWrapper("seasons");

const attributes = {
};

const toArray = (value) => (Array.isArray(value) ? value : [value]);

const computedProps = {};

const actions = {
  setProperty: action((state, { field, data }) => {
    state[field] = data;
  }),
  normalize: thunk((actions, data, { getStoreActions }) => {
    const { setEntities } = getStoreActions().entities;
    const { result, entities } = normalizeCollection2(data, "listingseason");
    setEntities({ entities });

    return result;
  }),
  getListings: thunk(({ normalize }, id) => {
    return seasonsRequester.get(`${id}/listing_seasons`).then(({ listing_seasons }) => {
      const ids = normalize(listing_seasons);
      return listing_seasons;
    });
  }),
  updateRewardRequest: thunk((_, { id, reward_amount, contribution_per_user, max_contributions }) => {
    return requester.patch(`/${id}`, { reward_amount, contribution_per_user, max_contributions });
  }),
  addListingToSeasonRequest: thunk((_, { season_ids, listing_ids }) => {
    const listing_season = { season_ids: toArray(season_ids), listing_ids: toArray(listing_ids) };

    return requester.post("", { listing_season });
  }),
  removeListingFromSeasonRequest: thunk((_, id) => {
    return requester.delete(`/${id}`);
  }),
};

const listingSeasons = {
  ...attributes,
  ...computedProps,
  ...actions,
};

export default listingSeasons;
