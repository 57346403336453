import { useState } from "react";
import HttpWrapper from "../../../redux/httpRequester";
import { getInstance } from "../../utils/web3wrapper";
import useWeb3 from "./useWeb3";

const requester = new HttpWrapper("social_profiles");

const useGitcoinScorer = (_address) => {
  const instance = getInstance() || {};
  const { loginFlow } = useWeb3();
  const [score, setScore] = useState(0);
  const [loading, setLoading] = useState(0);
  const connected = instance.isConnected();
  const address = _address || instance.selectedAddress();

  const updateScore = async () => {
    setLoading(true);
    return _updateScore(address).finally(() => setLoading(false));
  };

  const connect = () => loginFlow();

  const reFetchScore = () => {
    return getScore(address).then((_score) => {
      const score = parseFloat(_score) || 0;
      setScore(score);
      return score;
    });
  };

  return {
    address,
    loading,
    connected,
    score,
    connect,
    updateScore,
    reFetchScore,
  };
};

async function _updateScore(address) {
  return requester.post("/connect/gitcoin_passport", { address });
}

async function getScore() {
  try {
    const passportData = await requester.get("/gitcoin_score");
    if (passportData.score) {
      // if the user has a score, round it and set it in the local state
      const roundedScore = Math.round(passportData.score * 100) / 100;
      return roundedScore.toString();
    } else {
      // if the user has no score, display a message letting them know to submit thier passporta
      console.log("No score available, please add stamps to your passport and then resubmit.");
    }
  } catch (err) {
    console.log("error: ", err);
  }
}

export default useGitcoinScorer;
