import { MainContainer } from "@common/MainContainer";
import { Box, ListingCompactModular } from "@thrivecoin/ui-components";
import { textLinkify } from "@utils/text";
import { useStoreState } from "easy-peasy";
import styled from "styled-components";
import THeader from "../Season/BodySeason/WaysToContribute/THeader";
import { item } from "./dummy";

const RESPONSE_DELAY = 1000;

const NOOP = () => {};

const delayedresponse = (data, time) => () => {
  return new Promise((resolve) => setTimeout(() => resolve({ activity: data }), time));
};

const PreviewListing = ({ listing }) => {
  const { isMobile, isDesktop, isMiniTablet, isTablet } = useStoreState((state) => state.media);

  return (
    <MainContainer>
      <Wrapper>
        <Table>
          <THeader
            onSort={NOOP}
            isMobile={isMobile}
            isDesktop={isDesktop}
            isTablet={isTablet}
            isMiniTablet={isMiniTablet}
            isSeason={true}
          />
          <tbody>
            <FillerListing />
            <_PreviewListing listing={listing} />
            <FillerListing />
          </tbody>
        </Table>
      </Wrapper>
    </MainContainer>
  );
};

export const _PreviewListing = ({ listing, ...props }) => {
  const getSnapshotDetails = delayedresponse(listing, RESPONSE_DELAY);
  const getActivityDetails = delayedresponse(listing, RESPONSE_DELAY);

  return (
    <ListingCompactModular
      isAuthenticated
      reward={+listing.reward_amount}
      canCompleteMultipleTimes={listing.unlimited_contributions_per_user}
      type={listing.contribution_app}
      manuallyVerification={listing.verification_method === "manual"}
      custom_url={listing.custom_url}
      completedCount={0}
      socialProfileConnected
      manuallyComplete={NOOP}
      readMore={NOOP}
      connectSocialMedias={NOOP}
      getSnapshotDetails={getSnapshotDetails}
      getActivityDetails={getActivityDetails}
      onShare={NOOP}
      {...listing}
      {...props}
    />
  );
};

const FillerListing = () => (
  <ListingCompactModular
    className="blur"
    isAuthenticated
    reward={+item.reward_amount}
    canCompleteMultipleTimes={item.unlimited_contributions_per_user}
    type={item.contribution_app}
    manuallyVerification={item.verification_method === "manual"}
    custom_url={item.custom_url}
    completedCount={0}
    manuallyComplete={NOOP}
    readMore={NOOP}
    connectSocialMedias={NOOP}
    getSnapshotDetails={NOOP}
    getActivityDetails={NOOP}
    description={textLinkify(item.description)}
    onShare={NOOP}
    {...item}
  />
);

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .activity-btn{
    display: none;
  }
`;

const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  tbody:before {
    content: ".";
    display: block;
    line-height: 25px;
    text-indent: -99999px;
  }

  tbody {
    tr:first-child,
    tr:nth-last-child(2) {
      filter: blur(4px);
      pointer-events: none;
    }
  }
`;

export default PreviewListing;
