import AvatarUsername from "@common/UsernameAvatar";
import { Box, Text, TokenReward } from "@thrivecoin/ui-components";
import styled from "styled-components";

const RowElement = ({
  username,
  _group,
  rewards_earned,
  onProfileClick,
  image_url,
  index,
  user_id,
  showBorderTop,
  rewards_token,
  contributions_count,
  contributor_rank,
  personal,
  isDesktop,
  isCommunity,
  topThreeRanks,
}) => {
  const topThree = topThreeRanks.includes(contributor_rank);
  const isTenth = contributor_rank === 10;
  const bgColor = topThree ? "01Secondary300" : personal ? "03Primary300" : "surfaces";
  const dataColor = topThree || personal ? "02Primary500" : "01Primary700";
  const borderColor = topThree ? "01Primary0" : isTenth ? "03Primary500" : "01Primary200";
  const paddingRows = isDesktop ? "24px" : "16px 24px";

  return (
    <>
      <RowStyled
        borderColor={borderColor}
        showBorderTop={showBorderTop}
        bgColor={bgColor}
        borderWidth={isTenth && "2px"}
      >
        <DataElement padding={topThree && paddingRows}>
          <Box flex alignItems="center" gap="8px">
            {!!_group && (
              <AvatarUsername
                color={dataColor}
                username={username}
                dataID={_group.id}
                onProfileClick={onProfileClick}
                image_url={image_url}
                id={topThree ? null : contributor_rank}
                index={index}
                user_id={user_id}
                imageWidth={!topThree ? "44px" : "54px"}
                imageHeight={!topThree ? "44px" : "56px"}
                contributor_rank={contributor_rank}
                personal={personal}
                isContributor
              />
            )}
            {personal && contributor_rank < 101 && (
              <Text typography="body/medium" color="02Primary500" bold>
                (You are a Top 100 contributor)
              </Text>
            )}
          </Box>
        </DataElement>

        {!isCommunity && (
          <DataElement padding="24px 16px 24px 0">
            <Box relative flex alignItems="center" justifyContent="start" gap="6px">
              <TokenReward
                iconSize="40px"
                size="30px"
                lHeight="34px"
                currencySize="16px"
                currencyLHeight="24px"
                color={dataColor}
                amount={+rewards_earned}
                token={rewards_token}
                hideText
              />
            </Box>
          </DataElement>
        )}
        <DataElement>
          <Box relative flex alignItems="center" justifyContent="start">
            <Text extraBold typography="body/medium" color={dataColor}>
              {contributions_count}
            </Text>
          </Box>
        </DataElement>
      </RowStyled>
    </>
  );
};

const RowStyled = styled.tr`
  background-color: ${({ bgColor, theme }) => bgColor && (theme.colors[bgColor] || bgColor)};
  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    td:first-child {
      border-top-left-radius: 8px;
    }
    td:last-child {
      border-top-right-radius: 8px;
    }
  }

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    td:first-child {
      border-bottom-left-radius: 8px;
    }
    td:last-child {
      border-bottom-right-radius: 8px;
    }
  }

  td {
    border-bottom: 1px solid ${({ borderColor, theme }) => borderColor && (theme.colors[borderColor] || borderColor)};
    border-top: ${({ borderTop }) => borderTop && "1px solid #8DCF9F"};
    ${({ borderWidth }) => borderWidth && `border-width:${borderWidth}`};
  }

  td:last-child {
    padding-left: 0;
  }
`;

const DataElement = styled.td`
  padding: ${({ padding }) => padding || `32px 24px`};
  ${({ pointer }) => pointer && `cursor: pointer;`}
`;

export default RowElement;
