import { thunk } from "easy-peasy";
import HttpWrapper from "../httpRequester";

const requester = new HttpWrapper("user_audits");

const actions = {
  unBan: thunk(({ unRegulate }, args) => {
    return unRegulate({ action: "unban", ...args });
  }),
  unFlag: thunk(({ unRegulate }, args) => {
    return unRegulate({ action: "unflag", ...args });
  }),

  unRestrict: thunk(({ unRegulate }, args) => {
    return unRegulate({ action: "unrestrict", ...args });
  }),

  unRegulate: thunk((_, { action, user_id, additional_notes, message, community_id }) => {
    return requester.post(`${user_id}/${action}`, { additional_notes, message, community_id });
  }),
  ban: thunk(({ regulate }, args) => {
    return regulate({ action: "ban", ...args });
  }),
  flag: thunk(({ regulate }, args) => {
    return regulate({ action: "flag", ...args });
  }),
  restrict: thunk(({ regulate }, args) => {
    return regulate({ action: "restrict", ...args });
  }),
  regulate: thunk((_, { action, user_id, reason, message, additional_notes, community_id }) => {
    return requester.post(`${user_id}/${action}`, { reason, message, additional_notes, community_id });
  }),
  createRequestReview: thunk((_, { user_audit_id, message }) => {
    return requester.post(`${user_audit_id}/request_review`, { message });
  }),
};

const userAudits = {
  ...actions,
};

export default userAudits;
