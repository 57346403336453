import HeaderElement from "@common/HeaderElement";
import { Box, Button, Checkbox, getTypography, IconSvg, Text, Tooltip } from "@thrivecoin/ui-components";
import styled from "styled-components";
import { HeaderCheckbox } from "./HeaderCheckbox";

const STATUS_MESSAGE = "Inactive. Click “Add Reward” to turn this contribution active.";

const LIMIT_MESSAGE = "Reward limit reached. To reactivate this contribution, increase the limit via “Edit Rewards”";

export const ListingsTable = styled(
  ({ listings, showStatus, showRewards, className, listingProps, rowClass, selectedIDs, selectAll, children }) => {
    const rows = listings.map((listing) => (
      <ListingRow
        {...listing}
        {...listingProps}
        key={listing.id}
        showStatus={showStatus}
        showRewards={showRewards}
        reward={listing.reward_amount}
        className={rowClass}
        selected={selectedIDs.includes(listing.id)}
      />
    ));

    const onSort = () => {};

    return (
      <table className={className}>
        <thead>
          <tr>
            <th>
              <HeaderCheckbox selectAll={selectAll}>{children}</HeaderCheckbox>
            </th>
            <HeaderElement
              text={
                <>
                  <IconSvg icon="globe-not-filled" width="16px" spacing="ml-1 mr-5+2" />
                  Contribution
                </>
              }
              name="contributor"
              onSort={onSort}
            />
            {showRewards && (
              <>
                <th>Reward</th>
                <th>Max Rewards</th>
              </>
            )}
            {showStatus && <th>Status</th>}
            <th></th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  }
)`
  width: 100%;
  border-spacing: 0;
  text-align: left;

  thead th {
    ${getTypography("body/small")}
  }

  tbody {
    td[data-limit="true"] {
      color: ${({ theme }) => theme.colors["error2"]};
    }

    tr[data-selected="true"] {
      background: ${({ theme }) => theme.colors["manageCard3"]};
    }

    tr {
      background: ${({ theme }) => theme.colors["01Primary0"]};
    }

    tr:first-child {
      td:first-child {
        border-top-left-radius: 8px;
      }

      td:last-child {
        border-top-right-radius: 8px;
      }
    }

    tr:last-child {
      td:first-child {
        border-bottom-left-radius: 8px;
      }

      td:last-child {
        border-bottom-right-radius: 8px;
      }
    }
    tr:not(:last-child) td {
      border-bottom: 1px solid ${({ theme }) => theme.colors["01Primary200"]};
    }
  }

  td,
  th {
    padding: 16px;
  }

  th {
    white-space: nowrap;
  }

  img {
    width: 26px;
    height: 26px;
    vertical-align: top;
  }

  ${Button} {
    white-space: nowrap;
  }
`;

ListingsTable.defaultProps = {
  listings: [],
  selectedIDs: [],
  listingProps: {},
};

export const ListingRow = ({
  name,
  image_url,
  id,
  listing_id,
  showStatus,
  showRewards,
  reward,
  max_rewards,
  addSeason,
  updateReward,
  className,
  selected,
  status,
  OptionsComponent,
  previewListing,
  limit_reached,
  ...props
}) => (
  <tr data-id={id} className={className} data-selected={selected} data-status={status}>
    <td>
      <Checkbox name={`checkbox-${id}`} checked={selected} data-id={id} kind="gray-check" />
    </td>
    <td>
      <Box flex gap="16px">
        <CircleImage src={image_url} />
        <Text typography="body/medium" bold>
          {name}
        </Text>
      </Box>
    </td>
    {showRewards && (
      <>
        <td>{reward}</td>
        <td data-limit={limit_reached}>
          <Tooltip
            direction="top"
            $minWidth="300px"
            bgColor="01Primary700"
            $color="01Primary0"
            $show={limit_reached}
            message={LIMIT_MESSAGE}
          >
            {limit_reached && <IconSvg icon="alert-triangle" color="Error2" />}
            {max_rewards}
          </Tooltip>
        </td>
      </>
    )}

    {showStatus && (
      <td>
        <Tooltip
          direction="top"
          $minWidth="300px"
          bgColor="01Primary700"
          $color="01Primary0"
          message={STATUS_MESSAGE}
          $show={status == "pending"}
        >
          <CircleStatus icon={status == "pending" ? "x" : "check"} />
        </Tooltip>
      </td>
    )}
    <td>
      <Box flex gap="16px" justifyContent="end" alignItems="center">
        {addSeason && (
          <Button
            data-id={id}
            size="sm"
            borderRadius="40px"
            onClick={addSeason}
            typography="Other/Buttons"
            bgColor="01Secondary500"
            rightIcon="plus"
          >
            Add to Season
          </Button>
        )}
        {updateReward && (
          <Button
            data-id={id}
            size="sm"
            borderRadius="40px"
            typography="Other/Buttons"
            onClick={updateReward}
            width="146px"
            {...BUTTON_CONFIG[status]}
          >
            {BUTTON_CONFIG[status].text}
          </Button>
        )}
        <CircledIcon icon="eye2" width="16px" onClick={previewListing} data-id={id} data-listing-id={listing_id} />
        <OptionsComponent id={id} {...props} />
      </Box>
    </td>
  </tr>
);

const BUTTON_CONFIG = {
  active: {
    typography: "Other/Buttons",
    kind: "outline",
    borderColor: "01Secondary500",
    text: "Edit Reward",
  },
  pending: {
    text: "Add Reward",
    rightIcon: "plus",
  },
};

const CircledIcon = styled(IconSvg)`
  border-radius: 50%;
  padding: 12px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors["01Primary300"]};
`;

const CircleStatus = styled(IconSvg)`
  border-radius: 50%;
  background: red;
  padding: 8px;
  color: white;
  background: ${({ theme, icon }) => (icon == "check" ? theme.colors["03Primary500"] : theme.colors["02Secondary500"])};
`;

const CircleImage = styled.img`
  border-radius: 50%;
  width: 26px;
  height: 26px;
`;

ListingRow.defaultProps = {
  description: "Vote on all 4 of the Questbook Domain Allocators polls on Snapshot [Raffle] -- Sep. 6 to Sep. 13",
  image: "https://thrivecoin-assets.s3.amazonaws.com/images/apps/discord.svg",
  reward: "-",
  maxReward: "-",
  selectedIDs: [],
};

export default ListingsTable;
