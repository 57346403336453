import { useStoreActions, useStoreState } from "easy-peasy";
import { useContext, useEffect, useMemo } from "react";
import { Navigate, Route, Routes, useNavigate, useParams } from "react-router-dom";
import Loader from "../Loader";
import AddListings from "./AddListings";
import { CommunityContext } from "./CommunityContext";
import CommunityEditor from "./CommunityEditor";
import CommunityPage from "./CommunityPage";
import ConnectSocialProfilesPage from "./ConnectSocialProfilesPage";
import { MANAGER_ROLES } from "./Constants";
import TermsPrivacy from "./ContentPages/TermsPrivacy";
import TermsServices from "./ContentPages/TermsServices";
import Dashboard from "./Dashboard";
import DiscordSignUpPage from "./DiscordSignUpPage";
import Layout from "./Layout";
import Legend from "./Legend";
import ListingEditor from "./ListingEditor";
import ManageCommunities from "./ManageCommunities";
import { createAccountRegex, resetPasswordRegex } from "./modals/SignInFlowModal";
import PendingContributions from "./PendingContributions";
import Profile from "./Profile";
import RafflePage from "./Raffle";
import SeasonPage from "./Season";
import SeasonEditor from "./SeasonEditor";

const COMMUNITY_ROOT_PATH = "communities/:community_id";

const ROUTES = [
  { Component: CommunityPage, index: true },
  { Component: CommunityPage, path: ":referral_code" },
  { Component: CommunityPage, path: "reset_password/:token" },
  { Component: CommunityPage, path: "create/:code" },
  { Component: CommunityPage, path: "challenge_status/:challenge_id/:listing_id" },
  { Component: PendingContributions, path: "contributions/pending", roles: MANAGER_ROLES },
];

const mapRoutes = ({ Component, path, childrenRoutes, index, roles }) => (
  <Route
    key={path || index}
    index={index}
    path={path}
    element={
      <CommunityProtectedRoute roles={roles}>
        <Component />
      </CommunityProtectedRoute>
    }
  >
    {childrenRoutes && childrenRoutes.map(mapRoutes)}
  </Route>
);

const CommunityProtectedRoute = ({ roles, children }) => {
  const { roles_by_community } = useStoreState((state) => state.user.user);
  const { community_id } = useParams();
  const sessionHandled = useStoreState((state) => state.authentication.sessionHandled);
  const role = roles_by_community[community_id];

  if (roles && !roles_by_community.loading && sessionHandled && !roles.includes(role)) {
    return <Navigate replace to="/" />;
  }

  return children;
};

const KEEP_PATH_REGEX = [resetPasswordRegex, createAccountRegex];

const RedirectToDashboard = () => {
  const navigate = useNavigate();

  useEffect(() => {
    let url = "/";
    if (KEEP_PATH_REGEX.some((regex) => regex.test(window.location.pathname))) {
      url += window.location.pathname;
    }
    navigate(url);
  }, []);

  return <></>;
};

const ProtectedRouteCommunity = ({ children }) => {
  const { isAdmin } = useStoreState((state) => state.community);
  const { roles_by_community } = useStoreState((state) => state.user.user);

  if (roles_by_community.loading) {
    return <Loader />;
  }

  if (!isAdmin) {
    return <Navigate replace to="/" />;
  }

  return children;
};

const PermissionRoute =
  (attribute) =>
  ({ children }) => {
    const roles = useStoreState((state) => state.user);

    if (!roles[attribute]) {
      return <Navigate replace to="/" />;
    }

    return children;
  };

//TODO: since we dont have communityID on the routes,
// we need to make a part of the redirection inside the respective editorContext
// The fact that we use slugs is a minor issue we can work around

const AdminRoute = PermissionRoute("isAnyAdmin");

const ThriveAdminRoute = PermissionRoute("isThriveAdmin");

const ProtectedPages = ({ children }) => {
  const { confirmed } = useStoreState((state) => state.user.user);
  if (!confirmed) {
    return <Navigate replace to="/" />;
  }

  return children;
};

const InitialRoute = () => {
  const { isCustomDomain } = useContext(CommunityContext);
  const { listing_id } = useParams();
  const { showModal } = useStoreActions((actions) => actions.modals);

  useEffect(() => {
    if (listing_id) {
      showModal({ modalName: "ListingShowModal", listingID: listing_id });
    }
  }, []);

  return isCustomDomain ? <CommunityPage /> : <Dashboard />;
};

export default function Router() {
  const routes = useMemo(() => {
    return ROUTES.map(mapRoutes);
  }, []);

  return (
    <>
      <Routes>
        <Route
          path="communities/optimism-ambassadors"
          element={<Navigate replace to="/communities/thank-optimism" />}
        />
        <Route path={COMMUNITY_ROOT_PATH} element={<Layout />}>
          {routes}
        </Route>
        <Route path="*" element={<RedirectToDashboard />} />
        <Route
          path="/"
          element={
            <Layout>
              <InitialRoute />
            </Layout>
          }
        />
        <Route
          path="/listings/:listing_id"
          element={
            <Layout>
              <InitialRoute />
            </Layout>
          }
        />
        <Route
          path="/referred/:referral_code"
          element={
            <Layout>
              <Dashboard />
            </Layout>
          }
        />
        <Route path=":provider/connect" element={<ConnectSocialProfilesPage />} />
        <Route path="discord_sign_up/:discord_genereated_uid" element={<DiscordSignUpPage />} />
        <Route
          path={`${COMMUNITY_ROOT_PATH}/discord_sign_up/:discord_genereated_uid`}
          element={<DiscordSignUpPage />}
        />
        <Route
          path="/seasons/:id"
          element={
            <Layout>
              <SeasonPage />
            </Layout>
          }
        ></Route>
        <Route
          path="/raffles/:id"
          element={
            <Layout>
              <RafflePage />
            </Layout>
          }
        ></Route>
        <Route path="/missions/:id" element={<RedirectWithParams to="/seasons/:id" />} />
        <Route
          path="manage"
          element={
            <AdminRoute>
              <Layout />
            </AdminRoute>
          }
        >
          <Route path="communities" element={<ManageCommunities />} />
          <Route path="communities/:id" element={<ManageCommunities.ManageTabsContainer />} />
          <Route path="communities/:id/edit" element={<CommunityEditor />} />
          <Route path="seasons/:id/edit" element={<SeasonEditor edit />} />
          <Route path="seasons/:id/add-listings" element={<AddListings />} />
        </Route>
        <Route
          path="manage"
          element={
            <ThriveAdminRoute>
              <Layout />
            </ThriveAdminRoute>
          }
        >
          <Route path="listings/create" element={<ListingEditor />} />
          <Route path="listings/:id/edit" element={<ListingEditor />} />
          <Route path="communities/create" element={<CommunityEditor />} />
          <Route path="seasons/create" element={<SeasonEditor />} />
        </Route>
        <Route
          path="community_editor/:community_id"
          element={
            <ProtectedRouteCommunity>
              <Layout>
                <CommunityEditor />
              </Layout>
            </ProtectedRouteCommunity>
          }
        ></Route>
        <Route
          path="legend"
          element={
            <ProtectedPages>
              <Layout>
                <Legend />
              </Layout>
            </ProtectedPages>
          }
        />
        <Route
          path="current_user/profile"
          element={
            <ProtectedPages>
              <Layout>
                <Profile />
              </Layout>
            </ProtectedPages>
          }
        />
        <Route
          path=":user_id/profile"
          element={
            <Layout>
              <Profile />
            </Layout>
          }
        />
        <Route path="terms_privacy" element={<TermsPrivacy />} />
        <Route path="terms_services" element={<TermsServices />} />
      </Routes>
    </>
  );
}

const RedirectWithParams = ({ to }) => {
  const params = useParams();
  const keys = Object.keys(params);
  const url = keys.reduce((prev, key) => {
    return prev.replace(`:${key}`, params[key]);
  }, to);

  return <Navigate to={url} replace />;
};
