import { Box, Checkbox, ContextMenu, IconSvg } from "@thrivecoin/ui-components";
import { useState } from "react";

export const HeaderCheckbox = ({ children, selectAll }) => {
  const [checked, setState] = useState(false);

  const onChange = (event) => {
    event.stopPropagation();
    setState(event.target.checked);
  };

  const onClick = (e) => {
    if (e.target.type == "checkbox") {
      selectAll(e.target.checked);
    }
  };

  return (
    <Box flex alignItems="center" gap="4px" onClick={onClick}>
      <Checkbox checked={checked} onChange={onChange} kind="gray-check" />
      {children && <ContextMenu ContextButton={ContextButton}>{children}</ContextMenu>}
    </Box>
  );
};

const ContextButton = ({ onClick }) => <IconSvg onClick={onClick} icon="sort-down" width="12px" />;
