import debounce from "@utils/debounce";
import { useEffect, useState } from "react";

const PERSISTENT_OPTIONS_INITIAL = [{ id: "all", value: "all", label: "All", selected: true, addLine: true }];

const formatTagOptions = (options, onClick) => {
  return options.map((option) => ({
    id: option.id,
    value: option.id,
    label: option.label || `Season ${option.id}`,
    selected: false,
    onClick,
  }));
};
const initialCommunity = (community, onItemClick) => {
  return {
    title: "Community",
    selectedOptions: [
      {
        id: community.id,
        value: community.name,
        label: community.name,
        onClick: onItemClick,
      },
    ],
    options: [
      {
        id: community.id,
        value: community.name,
        label: community.name,
        onClick: onItemClick,
      },
    ],
    disabled: true,
  };
};

const useTagFilters = (publicSeasons, filterFN, sortProps, community) => {
  const [persistentOptions, setPersistentOptions] = useState([...PERSISTENT_OPTIONS_INITIAL]);
  const [text, setText] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [_community, setCommunity] = useState([]);
  const [_seasons, setSeasons] = useState([]);
  const [selectedSeasons, setSelectedSeasons] = useState([]);

  const onTagClick = (event) => {
    const value = event.value || event.target.dataset.value;

    setPersistentOptions((options) =>
      options.map((tag) => ({
        ...tag,
        selected: tag.value == value,
      }))
    );
  };

  const _onTextChange = (text) => {
    sortProps.onChange(text);
    const updatedTags = persistentOptions.map((tag) => ({
      ...tag,
      selected: tag.value === persistentOptions[0].value,
    }));

    setPersistentOptions(updatedTags);
  };

  const onTextChange = debounce(_onTextChange, 3000);

  const onItemClick = (seasons) => {
    setSelectedSeasons((prevSeasons) => {
      const isItemInSeasons = prevSeasons.some((item) => item.id === seasons.id);

      if (isItemInSeasons) {
        return prevSeasons.filter((item) => item.id !== seasons.id);
      } else {
        return [...prevSeasons, { ...seasons }];
      }
    });
  };

  useEffect(() => {
    const formattedSeasons = formatTagOptions(publicSeasons, onTagClick);
    const formattedTags = [...PERSISTENT_OPTIONS_INITIAL, ...formattedSeasons];
    setPersistentOptions(formattedTags);
  }, [publicSeasons]);

  useEffect(() => {
    setCommunity(initialCommunity(community, onItemClick));

    const formattedFiltersSeasons = {
      title: "Seasons",
      selectedOptions: selectedSeasons,
      options: formatTagOptions(publicSeasons, onItemClick),
    };

    setSeasons(formattedFiltersSeasons);

    const filterOptions = [_community, _seasons].map((collection) => {
      collection.onItemClick = onItemClick;
      return collection;
    });

    setFilterOptions(filterOptions);
  }, [_community.length, _seasons.length]);

  const doSearch = () => {
    filterFN(text, selectedSeasons);
  };

  useEffect(() => {
    setFilterOptions((prevFilterOptions) => {
      return prevFilterOptions.map((collection) => ({
        ...collection,
        selectedOptions: selectedSeasons,
      }));
    });
  }, [selectedSeasons]);

  return {
    text,
    persistentOptions,
    onTextChange,
    setText,
    onTagClick,
    onItemClick,
    filterOptions: filterOptions,
    selectedSeasons,
    doSearch,
    disabledFilterBtn: selectedSeasons.length === 0,
    gridTemplateColumns: "1fr 1fr 218px",
  };
};

export default useTagFilters;
