import DebouncedSearchInput from "@common/DebouncedSearchInput";
import FilterButton from "@common/FilterButton";
import { Box, breakpoints, Loader, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useState } from "react";
import styled from "styled-components";
import pluralize from "../../../../utils/pluralize";
import LoadMore from "../../../common/LoadMore";
import { SearchEmpty } from "../../../EmptyContent";
import { GrayContainer, Wrapper } from "../common";
import TableContributions from "./TableContributions";

const reference = { pagination: {} };
const setPagination = (pagination) => (reference.pagination = pagination);

const configProfile = {
  padding: "0",
  width: "100%",
  borderRadius: "0",
};

const TableTab = ({
  children,
  sortOptions,
  data,
  pagination,
  sortProps,
  title,
  titleSearch,
  justifyTitle,
  rowProps,
  modalProps,
  TableComponent,
  MobileTableComponent,
  $isContributors,
  top5Data,
  top10Data,
  loadMoreTop5,
  loadMoreTop10,
  top5Pagination,
  top10Pagination,
  onSearch,
  isContributions,
  showCommunity,
  isFromProfile,
  WrapperKlass,
  showFilter,
}) => {
  const { showModal } = useStoreActions((actions) => actions.modals);
  const { isMobile, isTablet, isMiniTablet, isDesktop } = useStoreState((state) => state.media);
  const { onSort, loading, sortType, searchValue, sortDirection, onFilterApply, onLoadMore, onChange } = sortProps;
  const sortValue = { value: sortType, direction: sortDirection };
  const [sortingType, setSortingType] = useState({});
  const isSearching = !!searchValue;
  const isMyRank = rowProps.myRank?.sortIndex >= 0;
  const dataResults = pagination ? pagination?.total : data?.length;
  const resultsFound =
    !loading && `There are ${dataResults} ${pluralize("contribution", dataResults)} based on your search`;
  const headingText = searchValue && !isMyRank ? resultsFound : title;
  let content;
  let _justifyTitle = titleSearch ? "space-between" : justifyTitle;
  setPagination(pagination);
  const showForDesktop = (!isMobile && $isContributors) || isContributions;
  const fromProfileProps = isFromProfile ? configProfile : {};

  const onFilter = () => {
    showModal({
      modalName: "FilterModal",
      searchValue,
      onChange,
      sortValue,
      onSubmit: onFilterApply,
      ...modalProps,
      sortOptions,
      setSortingType,
      sortingType,
    });
  };

  if (!searchValue && loading) {
    content = (
      <Box width="100%" spacing="m-a p-7" flex alignItems="center" justifyContent="center">
        <Loader />
      </Box>
    );
  } else if (!data || data?.length === 0) {
    content = <SearchEmpty />;
  } else {
    content = (
      <>
        {showForDesktop ? (
          <TableComponent
            isSearching={isSearching}
            data={data}
            total={pagination?.total}
            loading={loading}
            onSort={onSort}
            sortDirection={sortDirection}
            sortType={sortType}
            resultsFound={resultsFound}
            searchValue={searchValue}
            top5Data={top5Data}
            top10Data={top10Data}
            top5Pagination={top5Pagination}
            top10Pagination={top10Pagination}
            loadMoreTop5={loadMoreTop5}
            loadMoreTop10={loadMoreTop10}
            onSearch={onSearch}
            showCommunity={showCommunity}
            isFromProfile={isFromProfile}
            isTablet={isTablet}
            {...rowProps}
          />
        ) : (
          <MobileTableComponent
            isSearching={isSearching}
            data={data}
            total={pagination?.total}
            onSearch={onFilter}
            resultsFound={resultsFound}
            {...rowProps}
            isTablet={isTablet}
            isMobile={isMobile}
            top5Data={top5Data}
            top10Data={top10Data}
            top5Pagination={top5Pagination}
            top10Pagination={top10Pagination}
            loadMoreTop5={loadMoreTop5}
            loadMoreTop10={loadMoreTop10}
          />
        )}
        {pagination && <LoadMore pagination={pagination} loadMore={onLoadMore} />}
      </>
    );
  }

  return (
    <WrapperKlass>
      <Wrapper
        $isContributors={$isContributors}
        color="01Primary700"
        bgColor={isFromProfile ? "surfaces" : "transparent"}
        borderRadius={fromProfileProps.borderRadius}
      >
        <TitleSearchContainer
          flex
          justifyContent={_justifyTitle}
          spacing={isFromProfile ? "" : "mb-6+2"}
          md-spacing={isMiniTablet && $isContributors && "mx-0 mb-6"}
          sm-spacing="mb-0"
        >
          <Text bold typography="heading/small">
            {headingText}
          </Text>
          {isMobile && showFilter && <FilterButton onSearch={onFilter} icon="filter" />}
        </TitleSearchContainer>
        {children}
        <Box>{content}</Box>
      </Wrapper>
    </WrapperKlass>
  );
};

const TitleSearchContainer = styled(Box)`
  gap: 24px;
  flex-direction: column;
  align-items: baseline;

  @media ${breakpoints.belowDesktop} {
    .search {
      width: 100%;
    }
  }

  @media ${breakpoints.desktop} {
    flex-direction: row;
    align-items: center;
    gap: 0px;
  }
`;

TableTab.defaultProps = {
  WrapperKlass: GrayContainer,
  titleSearch: false,
  justifyTitle: "space-between",
  rowProps: {},
  modalProps: {},
  TableComponent: TableContributions,
  isFromProfile: false,
  showFilter: true,
};

export default TableTab;
