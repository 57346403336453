import { Box, breakpoints, Button, IconSvg, Input, Select, Text, TokenReward } from "@thrivecoin/ui-components";
import React, { useContext } from "react";
import styled from "styled-components";
import { TOKEN_OPTIONS } from "../../../constants";
import DateTimePicker from "../../common/DateTimePicker";
import IconPicker from "../../common/IconPicker";
import Switcher from "../../common/Switcher";
import { FloatInput } from "../../ListingEditor/common";
import { DEFAULT_PRIZE } from "../constants";
import { SeasonEditorContext } from "../SeasonEditorContext";
import { StyledInput } from "./StyledInputs";

const PrizeItem = ({ index, icon, text, updatePrize, removePrize, errors }) => {
  const setSelected = (name) => updatePrize("icon", name, index);
  const onChangeText = (e) => updatePrize("text", e.target.value, index);
  const onRemovePrize = () => removePrize(index);

  return (
    <PrizeWrapper>
      <Box flex alignItems="center" justifyContent="space-between" spacing="mb-3">
        <Text>{index + 1}. List Item</Text>
        <Box pointer onClick={onRemovePrize}>
          <IconSvg icon="x" width="20px" color="01Primary700" />
        </Box>
      </Box>
      <PickersBox flex gap="12px">
        <IconPicker selected={icon} setSelected={setSelected} />
        <PrizeInput
          value={text}
          onChange={onChangeText}
          invalid={!!errors?.prizes_list?.text}
          alwaysShowValidation={!!errors?.prizes_list?.text}
          validationText={errors?.prizes_list?.text}
          placeholder=""
        />
      </PickersBox>
    </PrizeWrapper>
  );
};

const SeasonOverview = () => {
  const { errorValues, raffleEnabled, season, setSeason, updateSeason, setPreviewMode, toggleRaffle, isEditing } =
    useContext(SeasonEditorContext);

  const changeDates = (value, id) => {
    updateSeason(id, value);
  };

  const changeRaffleDate = (value) => {
    const raffle = { ...season.raffle };
    raffle.draw_date = value;
    updateSeason("raffle", raffle);
  };

  const preview = () => setPreviewMode(true);

  const addPrize = () => {
    const prizes = [...season.prizes];
    prizes.push(DEFAULT_PRIZE);
    updateSeason("prizes", prizes);
  };

  const updatePrize = (key, val, index) => {
    const prizes = [...season.prizes];
    const prize = { ...prizes[index] };
    prize[key] = val;
    prizes[index] = prize;
    updateSeason("prizes", prizes);
  };

  const changeExternalLink = (e) => {
    const raffle = { ...season.raffle };
    raffle.external_url = e.target.value;
    updateSeason("raffle", raffle);
  };

  const removePrize = (index) => {
    updateSeason(
      "prizes",
      season.prizes.filter((_, i) => i !== index)
    );
  };

  const onTokenChange = (event) => {
    const { name, value } = event.target;
    updateSeason(name, value);
  };

  const onMaxRewardChange = (e) => {
    const { id, value } = e.target;
    
    if (id) {
      updateSeason(id, value);
    }
  };

  const tokenOptions = TOKEN_OPTIONS.map(({ value, label }) => (
    <option key={value} value={value}>
      {label}
    </option>
  ));

  return (
    <Wrapper>
      <Content>
        <Text bold spacing="mb-4">
          Season Start (Required)
        </Text>
        <Text spacing="mb-6" sm-spacing="mb-5">
          We will always use UTC +0 Timezone as a start-of-the-season time
        </Text>
        <PickersBox flex gap="12px" spacing="mb-6">
          <DateTimePicker
            id="start_date"
            value={season.start_date}
            setValue={changeDates}
            invalid={!!errorValues?.start_date}
            validationText={errorValues?.start_date}
          />
          <DateTimePicker id="start_date" value={season.start_date} setValue={changeDates} timeSelector />
        </PickersBox>
        <Text bold spacing="mb-4">
          Season End (Required)
        </Text>
        <Text spacing="mb-6">We will always use UTC +0 Timezone as an end-of-the-season time</Text>
        <PickersBox flex gap="12px" spacing="mb-6">
          <DateTimePicker
            id="end_date"
            value={season.end_date}
            setValue={changeDates}
            invalid={!!errorValues?.end_date}
            validationText={errorValues?.end_date}
          />
          <DateTimePicker id="end_date" value={season.end_date} setValue={changeDates} timeSelector />
        </PickersBox>
        <Separator />
        {!isEditing && (
          <>
            <Box flex alignItems="center" justifyContent="space-between">
              <Text>Conducting raffle this season</Text>
              <Switcher checked={raffleEnabled} onChange={toggleRaffle} />
            </Box>
            <Separator />
          </>
        )}
        <Text typography="heading/medium" spacing="mb-6">
          Rewards
        </Text>
        <Box spacing="mb-6">
          <Text spacing="mb-3" bold>
            Custom Token (Required)
          </Text>
          <Select name="rewards_token" value={season.rewards_token} onChange={onTokenChange} disabled={raffleEnabled}>
            {tokenOptions}
          </Select>
        </Box>
        <Box>
          <Text typography="body/medium" spacing="mb-3" bold>
            Maximum Season Reward Distribution (Optional)
          </Text>
          <Box gap="16px" alignItems="center" flex>
            <FloatInput
              value={season.max_reward_amount}
              id="max_reward_amount"
              width="237px"
              onChange={onMaxRewardChange}
            />
            <TokenReward token={season.rewards_token} hideText hideAmount />
          </Box>
        </Box>
        <Text typography="body/medium" color="01Primary700" spacing="mt-6 mb-5">
          A short overview of the rewards that will be given away
        </Text>
      </Content>

      {season?.prizes?.map((item, index) => (
        <PrizeItem
          key={index}
          index={index}
          {...item}
          updatePrize={updatePrize}
          removePrize={removePrize}
          errors={errorValues}
        />
      ))}
      <Content>
        <Button
          bold
          width="100%"
          color="01Primary700"
          borderRadius="50px"
          kind="outline"
          rightIcon="plus"
          onClick={addPrize}
        >
          Add Item(s)
        </Button>

        {raffleEnabled && (
          <React.Fragment>
            <Separator />
            <Text bold spacing="mb-4">
              Raffle will happen on (Required)
            </Text>
            <Text spacing="mb-5">We will always use UTC +0 Timezone as a raffle time</Text>
            <PickersBox flex gap="12px" spacing="mb-6">
              <DateTimePicker value={season.raffle.draw_date} setValue={changeRaffleDate} />
              <DateTimePicker value={season.raffle.draw_date} setValue={changeRaffleDate} timeSelector />
            </PickersBox>
            <Separator />
            <StyledInput
              label="Raffle Event Link (Optional)"
              value={season.raffle.external_url}
              onChange={changeExternalLink}
              placeholder="This is the URL where the raffle can be accessed. E.g. Zoom link"
            />
          </React.Fragment>
        )}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  max-width: 587px;
  overflow-y: auto;

  @media ${breakpoints.tablet} {
    width: 521px;
  }
`;

const Content = styled(Box)`
  padding: 0 1rem;

  @media ${breakpoints.tablet} {
    padding: 0 8px;
  }

  @media ${breakpoints.mobile} {
    padding: 0 1rem;
  }
`;

const Separator = styled(Box)`
  height: 1px;
  background: ${({ theme }) => theme.colors["01Primary200"]};
  margin: 2rem 0;
`;

const PrizeWrapper = styled(Box)`
  border-radius: 12px;
  padding: 20px 1rem;
  margin-bottom: 2rem;
  border: 1px solid ${({ theme }) => theme.colors["01Primary300"]};

  @media ${breakpoints.mobile} {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;

const PrizeInput = styled(Input)`
  & > div > input {
    font-weight: 600;
    background: ${({ theme }) => theme.colors["01Primary50"]};
  }
`;

const PickersBox = styled(Box)`
  @media ${breakpoints.mobile} {
    flex-direction: column;
  }
`;

const PreviewText = styled(Text)`
  @media ${breakpoints.mobile} {
    display: none;
  }
`;

export default SeasonOverview;
