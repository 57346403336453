import TabWrapper from "@common/TabWrapper";
import { Box, breakpoints, MarkdownText, Text } from "@thrivecoin/ui-components";
import { formatDate } from "@utils/formatting";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect } from "react";
import styled from "styled-components";
import { generateRulesContent } from "./data";

const RaffleFAQ = ({ raffle_id }) => {
  const { getRaffle } = useStoreActions((actions) => actions.raffle);
  const { raffle } = useStoreState((state) => state.raffle);
  const { season } = useStoreState((state) => state.season);

  useEffect(() => {
    getRaffle({ id: raffle_id });
  }, []);

  return (
    <TabWrapper>
      <WrapperInside>
        <LeftCol>
          <Text typography="heading/small" color="01Primary700" spacing="mb-5">
            Season {season.title} Rules
          </Text>
          <StyledBox flex direction="column" gap="5px">
            <Text typography="body/small" color="01Primary700" bold>
              Community
            </Text>
            <Text typography="body/medium" color="01Link" extraBold>
              {raffle.community_name}
            </Text>
          </StyledBox>
          <StyledBox flex direction="column" gap="5px">
            <Text typography="body/small" color="01Primary700" bold>
              Season
            </Text>
            <Text typography="body/medium" color="01Primary700" extraBold>
              {raffle.season_number}
            </Text>
          </StyledBox>
          <StyledBox flex direction="column" gap="5px">
            <Text typography="body/small" color="01Primary700" bold>
              {season.title} Start
            </Text>
            <Text typography="body/medium" color="01Primary700" extraBold>
              {formatDate(raffle.draw_date)}
            </Text>
          </StyledBox>
          <StyledBox flex direction="column" gap="5px">
            <Text typography="body/small" color="01Primary700" bold>
              {season.title} End
            </Text>
            <Text typography="body/medium" color="01Primary700" extraBold>
              {formatDate(season.end_date)}
            </Text>
          </StyledBox>
        </LeftCol>

        <RightCol>
          {" "}
          <MarkdownText color="01Primary700">
            {generateRulesContent(
              raffle.community_name,
              raffle.season_number,
              formatDate(raffle.draw_date),
              formatDate(season.end_date),
              raffle.prizes_count
            )}
          </MarkdownText>
        </RightCol>
      </WrapperInside>
    </TabWrapper>
  );
};

const WrapperInside = styled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 24x;
  border-radius: 12px;
  padding: 32px;
  padding-bottom: 58px;
  background: ${({ theme }) => theme.colors.surfaces};

  @media ${breakpoints.tablet} {
    padding: 34.74px 23px 24px 31.61px;
    gap: 30px;
  }
  @media ${breakpoints.miniTablet} {
    flex-direction: column;
    padding: 34.74px 23px 24px 31.61px;
    gap: 53.78px;
  }
  @media ${breakpoints.mobile} {
    flex-direction: column;
    padding: 40.85px 22.99px 24px 29px;
  }
`;

const LeftCol = styled(Box)`
  width: 100%;
  max-width: 338px;

  @media ${breakpoints.tablet} {
    margin-bottom: 32px;
    max-width: 270px;
  }
  @media ${breakpoints.miniTablet} {
    margin-bottom: 0;
    max-width: unset;
  }
  @media ${breakpoints.mobile} {
    margin-bottom: 24px;
  }
`;

const StyledBox = styled(Box)`
  margin-bottom: 17px;
  text-transform: capitalize;
`;

const RightCol = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 836px;
  margin-left: auto;
  @media ${breakpoints.miniTablet} {
    max-width: unset;
  }
`;

export default RaffleFAQ;
