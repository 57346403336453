import { Box, Button, Checkbox, IconSvg, Input, Modal, Text, TokenReward } from "@thrivecoin/ui-components";
import { useState } from "react";
import styled from "styled-components";
import { forceInteger } from "../ListingEditor/common";

const ConfigureListingRewardModal = ({ hideModal, description, updateRewardRequest, limit_reached, ...props }) => {
  const o_max_contributions = props.max_contributions;
  const [state, setState] = useState(props);
  const [loading, setLoading] = useState(false);
  const {
    reward_amount,
    contribution_per_user,
    max_contributions,
    unlimited_contributions_per_user,
    unlimited_completions,
  } = state;
  const maxRewards = reward_amount * max_contributions;
  const isInvalid = limit_reached ? o_max_contributions >= max_contributions : false;

  const onChange = ({ target }) => {
    const attribute = target.name;
    const { related } = target.dataset;
    let newState = {};
    if (target.type == "checkbox") {
      const value = target.checked;
      newState = { [attribute]: value, [related]: value ? " " : state[related] };
    } else {
      newState = { [attribute]: ~~target.value, [related]: undefined };
    }

    setState((state) => ({ ...state, ...newState }));
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    updateRewardRequest(state)
      .then(hideModal)
      .catch(() => setLoading(false));
  };

  return (
    <Modal open onClose={hideModal} maxWidth="684px" spacing="p-7 pt-4">
      <Form flex direction="column" gap="24px" onChange={onChange} onSubmit={onSubmit}>
        <Text bold color="02Primary500" typography="heading/medium">
          Add Reward to Contribution
        </Text>
        <Text>{description}</Text>
        <Separator />
        <InputGroup value={reward_amount} name="reward_amount" label="Contribution Value (Required)" hasIcon />
        <InputGroup
          value={contribution_per_user}
          name="contribution_per_user"
          label="Completions per User (Required)"
          data-related="unlimited_completions"
        >
          <Box flex gap="8px" alignItems="center">
            <Checkbox
              kind="gray-check"
              type="checkbox"
              checked={unlimited_completions}
              name="unlimited_completions"
              data-related="contribution_per_user"
            />
            <span>No limit</span>
          </Box>
        </InputGroup>
        <Separator />
        <InputGroup
          value={max_contributions}
          name="max_contributions"
          label="Maximum Number of Completions (Required)"
          data-related="unlimited_contributions_per_user"
        >
          <Box>
            {isInvalid && (
              <Box color="error2" flex>
                <IconSvg icon="alert-triangle" spacing="mr-2" />
                <Text>
                  Reward limit reached. If you wish to reactivate this contribution, please increase the Maximum Number
                  of Completions.
                </Text>
              </Box>
            )}
            <Box flex gap="8px" alignItems="center">
              <Checkbox
                kind="gray-check"
                type="checkbox"
                checked={unlimited_contributions_per_user}
                name="unlimited_contributions_per_user"
                data-related="max_contributions"
              />
              <span>No limit</span>
            </Box>
          </Box>
        </InputGroup>
        <Separator />

        <Box bgColor="02PrimaryGrey100" spacing="p-5" flex direction="column" gap="16px" borderRadius="12px">
          <Box>
            <Text typography="Body/Medium" bold>
              Max Rewards to Distribute
            </Text>
            <TokenReward hideText amount={maxRewards} token="thrive" fontSize="24px" size="32px" />
          </Box>
          <Box width="112px" minHeight="1px" bgColor="02PrimaryGrey300"></Box>
          <Text>
            Contribution Value: <b>{reward_amount}</b>
          </Text>
          <Text>
            Completions per User:<b>{contribution_per_user}</b>
          </Text>
          <Text>
            Maximum number of completion: <b>{max_contributions}</b>
          </Text>
        </Box>
        <Box flex gap="16px">
          <Button
            loading={loading}
            grow="1"
            kind="outline"
            color="02Primary700"
            borderColor="02PrimaryGrey300"
            onClick={hideModal}
          >
            Cancel
          </Button>

          <Button loading={loading} grow="1" color="02Primary0" type="submit" disabled={isInvalid}>
            Set Reward
          </Button>
        </Box>
      </Form>
    </Modal>
  );
};

const Separator = () => <Box minHeight="1px" bgColor="02PrimaryGrey150"></Box>;

const InputGroup = ({ label, value, name, hasIcon, children, ...props }) => (
  <Box flex gap="12px" direction="column">
    <Text typography="Body/Medium" bold>
      {label}
    </Text>
    <Box flex gap="16px">
      <Input name={name} value={value} onKeyPress={forceInteger} {...props} modal />
      {hasIcon && <IconSvg icon="logo-gold-circle" width="40px" />}
    </Box>
    {children}
  </Box>
);

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${Input} {
    max-width: 315px;
  }

  ${Button} {
    border-radius: 40px;
  }
`;

export default ConfigureListingRewardModal;
