import { useStoreState } from "easy-peasy";
import { useNavigate } from "react-router-dom";
import TableContributions from "../CommunityPage/Challenges/Contributions/TableContributions";
import TableTab from "../CommunityPage/Challenges/Contributions/TableTab";
import useSort from "../hooks/useSort";
import { PROFILE_CONTRIBUTON_OPTIONS } from "../SortOptions";
import { TabWrapper } from "./common";

const sortOrder = [
  "Contribution Name (A-Z)",
  "Contribution Name (Z-A)",
  "Reward (highest to lowest)",
  "Reward (lowest to highest)",
  "Date (newest to oldest)",
  "Date (oldest to newest)",
  "Community Name (A-Z)",
  "Community Name (Z-A)",
];

const sortArray = (array) => array.sort((a, b) => sortOrder.indexOf(a.label) - sortOrder.indexOf(b.label));

const modalProps = {
  sortOptions: sortArray(PROFILE_CONTRIBUTON_OPTIONS),
  backLabel: "Back to Profile Page",
  filterLabel: "Contributions",
};

const ProfileContributions = ({ user_id, isPublicProfile, getContributions }) => {
  const navigate = useNavigate();
  const { userContributions } = useStoreState((state) => state.contributions);
  const { username } = useStoreState((state) => state.authentication.publicUser);
  const contributions = userContributions(user_id);

  const title = isPublicProfile ? `${username}’s contributions` : "My Contributions";
  const sortProps = useSort(getContributions, "date", "user_id", user_id);

  const onCommunityClick = (e) => navigate(`/communities/${e.target.dataset.id}`);

  const rowProps = {
    onCommunityClick,
  };

  return (
    <TableTab
      WrapperKlass={TabWrapper}
      title={title}
      titleSearch={true}
      sortOptions={modalProps.sortOptions}
      TableComponent={TableContributions}
      data={contributions}
      sortProps={sortProps}
      rowProps={rowProps}
      modalProps={modalProps}
      isContributions={true}
      showCommunity={true}
      isFromProfile={true}
    />
  );
};

export default ProfileContributions;
