import { action, thunk } from "easy-peasy";
import { deNormalizeCollection, denormalizeEntity, normalizeCollection2 } from "../normalizerSchema";

const attributes = {
  activities: {},
  challenges: {},
  challengeSteps: {},
  communities: {},
  contribution_claims: {},
  contributions: {},
  groups: {},
  invitations: {},
  listings: {},
  members: {},
  membership_requests: {},
  notifications: {},
  seasons: {},
  tags: {},
  users: {},
  wallets: {},
  listing_seasons: {},
};

function mergeObjects(source, updated) {
  if (updated === null || updated === null) {
    return source;
  }

  const merged = { ...source };
  for (const [key, value] of Object.entries(updated)) {
    if (Array.isArray(value) && value.length > 0) {
      merged[key] = value;
    } else if (typeof value === "object" && !Array.isArray(value)) {
      merged[key] = mergeObjects(merged[key], value);
    } else if (!(key in merged)) {
      merged[key] = value;
    }
  }
  return merged;
}

const override = (state, newState) => {
  const keys = Object.keys(newState);
  //ex: users[17] = {...old, ...updated }
  keys.forEach((key) => (state[key] = { ...state[key], ...newState[key] }));
  // keys.forEach((key) => (state[key] = mergeObjects(state[key], newState[key])));
};

const actions = {
  updateEntity: thunk(({ setEntities }, { id, data, entityName }, { getState }) => {
    const entities = getState();
    const denormalizedData = denormalizeEntity(id, entities, entityName);
    const updatedData = [{ ...denormalizedData, ...data }];
    const result = normalizeCollection2(updatedData, entityName);
    setEntities(result);

    return result.result;
  }),
  normalizeCollection: thunk(({ setEntities }, { data, entityName }) => {
    const result = normalizeCollection2(data, entityName);
    setEntities(result);
    return result.result;
  }),
  denormalizeCollection: thunk((_, { ids, entityName }, getState) => {
    const entities = getState();
    return deNormalizeCollection(ids, entities, entityName);
  }),

  removeEntity: action((state, { id, entityType }) => {
    delete state[entityType][id];
  }),
  setEntities: action((state, { entities }) => {
    const entityNames = Object.keys(entities);
    entityNames.forEach((property) => {
      override(state[property], entities[property]);
      // state[property] = merge({}, state[property], entities[property]);
    });
  }),
  setBalance: action((state, { id, balance }) => {
    state.wallets[id].balance = balance;
  }),
};

const preferenaces = {
  ...attributes,
  ...actions,
};

export default preferenaces;
