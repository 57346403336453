import { Box } from "@thrivecoin/ui-components";
import styled from "styled-components";

export const CenteredBox = styled(Box)`
  width: min(1302px, 100% - 32px);
  margin: auto;
  position: relative;
`;

export const MainContainer = styled(CenteredBox)`
  padding-bottom: 64px;
`;
