import { toArray } from "@utils/formatting";
import { useStoreActions, useStoreState } from "easy-peasy";
import { createContext, useEffect, useMemo, useState } from "react";

const formatResponse = ({ listing_image_url: image_url, listing_name: name, listing_status: status, ...args }) => ({
  name,
  image_url,
  status,
  ...args,
});

export const AddListingsContext = createContext({
  statusCounts: {},
  season: {},
  communityListings: [],
  seasonListings: [],
  seasonListingsTotal: 0,
  loading: true,
  addListingsToSeason: () => {},
  removeListingFromSeason: () => {},
  dropAddListingToSeason: () => {},
  searchCommunitySeasons: () => {},
  searchListingSeasons: () => {},
  previewListing: () => {},
  updateReward: () => {},
});

const filterByText = (array, text, field = "description") => {
  return text ? array.filter((listing) => listing[field].includes(text)) : array;
};

export const AddListingsProvider = ({ id, children }) => {
  const { season } = useStoreState((state) => state.season);
  const { managableListings } = useStoreState((state) => state.listings);
  const { getSeasonToEdit } = useStoreActions((actions) => actions.season);
  const { getListings, addListingToSeasonRequest, removeListingFromSeasonRequest, updateRewardRequest } =
    useStoreActions((actions) => actions.listingSeasons);
  const { getCommunityListingsRequest } = useStoreActions((actions) => actions.listings);
  const { showModal } = useStoreActions((actions) => actions.modals);
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [seasonSearch, setSeasonSearch] = useState("");
  const [communitySearch, setCommunitysearch] = useState("");
  const idsToIgnore = listings.map(({ listing_id }) => listing_id);
  let communityListings = managableListings.filter(({ id }) => !idsToIgnore.includes(id));
  communityListings = filterByText(communityListings, communitySearch);
  let seasonListings = filterByText(listings, seasonSearch);

  const statusCounts = useMemo(() => {
    let counts = {};
    communityListings.forEach(({ status }) => {
      counts[status] = counts[status] ? counts[status] + 1 : 1;
    });
    return counts;
  }, [communityListings.length]);

  const addListingsToSeason = (listing_ids) =>
    addListingToSeasonRequest({ season_ids: id, listing_ids }).then(getListingSeasons);

  const removeListingFromSeason = (id) => {
    const ids = toArray(id).map((x) => parseInt(x));
    const promises = ids.map((id) => removeListingFromSeasonRequest(id));
    return Promise.all(promises).then(() => {
      const newListings = listings.filter((listing) => !ids.includes(listing.id));
      setListings(newListings);
    });
  };

  const dropAddListingToSeason = (event) => {
    const listingID = event.dataTransfer.getData("text/plain");
    addListingsToSeason(listingID);
  };

  const searchCommunitySeasons = (text) => setCommunitysearch(text || "");
  const searchListingSeasons = (text) => setSeasonSearch(text || "");

  const previewListing = (event) => {
    const { id, listingId } = event.currentTarget.dataset;
    const lookupID = listingId || id;
    const listing = managableListings.find((listing) => listing.id == lookupID);

    if (listing) {
      showModal({ modalName: "WaysToContributePreviewModal", listing });
    }
  };

  const updateReward = (e) => {
    const id = e.currentTarget.dataset.id;
    const listing = listings.find((listing) => listing.id == id);
    showModal({ modalName: "ConfigureListingRewardModal", ...listing, updateRewardRequest: _updateRewardRequest });
  };

  const _updateRewardRequest = (listing) => updateRewardRequest(listing).then(getListingSeasons);

  const getListingSeasons = () => {
    return getListings(id).then((listing_seasons) => {
      const listings = listing_seasons.map(formatResponse);
      setListings(listings);
    });
  };

  useEffect(() => {
    if (season.id != id) {
      getSeasonToEdit(id);
    } else {
      const promises = [getListingSeasons(), getCommunityListingsRequest({ id: season.community_id, per_page: 100 })];
      Promise.all(promises).finally(() => setLoading(false));
    }
  }, [id, season.id]);

  const value = {
    season,
    communityListings,
    seasonListingsTotal: listings.length,
    seasonListings,
    addListingsToSeason,
    removeListingFromSeason,
    dropAddListingToSeason,
    searchCommunitySeasons,
    searchListingSeasons,
    previewListing,
    updateReward,
    statusCounts,
    loading,
  };

  return <AddListingsContext.Provider value={value}>{children}</AddListingsContext.Provider>;
};
