import { Box, IconSvg, OptionsPanel, Text } from "@thrivecoin/ui-components";
import { useStoreActions } from "easy-peasy";
import styled from "styled-components";
import { DEFAULT_PROFILE_IMAGE } from "../../Constants";

const STATUSES = {
  active: {
    label: "Published",
    bgColor: "03Primary500",
  },
  draft: {
    label: "Draft",
    bgColor: "01Secondary500",
  },
  archived: {
    label: "Archived",
    bgColor: "02Primary200",
  },
};

const CardHeader = ({ id, name, logo_url, status, expanded, toggle, pending_contributions_count }) => {
  const logo = logo_url || DEFAULT_PROFILE_IMAGE;
  const currStatus = STATUSES[status] || STATUSES.archived;
  const chevIcon = expanded ? "chevron-up" : "chevron-down";
  const { showModal } = useStoreActions((actions) => actions.modals);

  const editCommunity = () => showModal({ modalName: "CommunityEditorModal", communityID: id });
  const onExportClick = () => {
    showModal({
      modalName: "ExportDataModal",
      id: id,
    });
  };

  return (
    <Box flex alignItems="center">
      <Logo src={logo} />
      <Text typography="heading/x-large">{name}</Text>
      <Box spacing="ml-a" flex gap="16px">
        <Status bgColor={currStatus.bgColor}>
          <Text extraBold color="01Primary0">
            {currStatus.label}
          </Text>
        </Status>
        <ActionButton options spacing="pt-1">
          <OptionsPanel right="0" minPanelWidth="281px" padding="0">
            <StyledBox justifyContent="space-between" flex alignItems="center" onClick={onExportClick}>
              <Text pointer typography="body/medium" color="01Primary700" bold>
                Export Data (CSV)
              </Text>{" "}
              <IconSvg icon="export-cloud" width="23px" height="16px" color="03Primary500" />
            </StyledBox>
            <Box height="1px" bgColor="01Primary200" />
            <Text onClick={editCommunity} pointer typography="body/medium" color="01Primary700" spacing="p-4">
              Edit Community
            </Text>
            <Text></Text>
          </OptionsPanel>
        </ActionButton>
        <ActionButton pointer data-id={id} onClick={toggle}>
          <IconSvg icon={chevIcon} width="28px" />
        </ActionButton>
      </Box>
    </Box>
  );
};

const Logo = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 20px;
`;

const Status = styled(Box)`
  padding: 0 32px;
  height: 37px;
  border-radius: 8px;
  display: flex;
  align-items: center;
`;

const PendingCount = styled(Text)`
  border: 1px solid red;
  border-radius: 50px;
`;

const ActionButton = styled(Box)`
  color: ${({ theme }) => theme.colors["03Primary500"]};
  width: 35px;
  height: 35px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors["03Primary500"]};

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ options }) =>
    !options &&
    ` & > * {
    pointer-events: none;
  }`}
`;

const StyledBox = styled(Box)`
  padding 16px 14px 16px 16px;
  cursor: pointer;
`;

export default CardHeader;
