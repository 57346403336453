import Coins from "@assets/profile/coins.svg";
import CoinsTablet from "@assets/profile/coins-tablet.svg";
import SHAPES from "@assets/profile/union-shapes.svg";
import { Box, breakpoints, IconSvg, Text } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useState } from "react";
import styled from "styled-components";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import { DisconnectButton } from "../common";

const WalletCard = ({
  id,
  title,
  address,
  balance,
  smallCard,
  disconnect,
  detectedWallet,
  bgColorCard,
  disableConnect,
  height,
  width,
  isFromVerificationModal,
  middleLineColor,
  hasError,
  notConnected,
  compact,
}) => {
  const [loading, setLoading] = useState(false);
  const { isMobile, isDesktop } = useStoreState((state) => state.media);
  const onCopyClipboard = useCopyToClipboard(address, "Your address has been copied.");
  const viewBalanceLink = `https://mumbai.polygonscan.com/token/0x7310E0195Ae639c1883c1B86ec49ae5def56E3d7?a=${address}`;
  const doDisconnect = () => {
    setLoading(true);
    disconnect(id).finally(() => setLoading(false));
  };

  return (
    <Wrapper relative>
      <StyledBox height={height} width={width} bgColorCard={bgColorCard || "03Secondary800"} $compact={compact}>
        {!disableConnect && (
          <>
            {!hasError && (
              <TopBox>
                <Box flex justifyContent="space-between">
                  <Text typography={smallCard ? "body/medium" : "body/large"} color="01Primary0" bold>
                    {title}
                  </Text>
                  {!notConnected && <CircleCheck icon="circle-check" />}
                </Box>
                {!smallCard && (
                  <BoxAmount flex alignItems="center" gap="14px">
                    <IconSvg icon="thrive" width="40px" />
                    <Box alignItems="center" gap="4px" flex>
                      <Text typography="body/x-large" color="01Primary0" extraBold>
                        {balance}
                      </Text>
                      <Text typography="body/medium" color="01Primary0" extraBold>
                        $THRIVE
                      </Text>
                    </Box>
                  </BoxAmount>
                )}
              </TopBox>
            )}

            {hasError && <XMark icon="circle-xmark" color="02Primary0" width="40px" />}
            {(!detectedWallet || isFromVerificationModal) && (
              <BoxAddress
                onClick={onCopyClipboard}
                spacing={isFromVerificationModal && "pl-8"}
                bgColor={middleLineColor}
                color={hasError ? "02Primary0" : "02Primary0"}
              >
                <Text typography="body/large" bold>
                  {address}
                </Text>
               {!isFromVerificationModal && <IconSvg width="28px" icon="copy" />}
              </BoxAddress>
            )}
            {detectedWallet && <BoxAddress bgColor="01Primary400"></BoxAddress>}

            {!smallCard && (
              <>
                <Link href={viewBalanceLink} target="_blank">
                  <Text typography="body/small" color="02Primary500" bold>
                    View Balance
                  </Text>
                  <IconSvg icon="arrow-up-right" width="10px" color="02Primary500" />
                </Link>
                <CoinsImg src={isDesktop ? Coins : CoinsTablet} />
              </>
            )}
          </>
        )}
      </StyledBox>

      {disconnect && (
        <DisconnectButton
          loading={loading}
          onClick={doDisconnect}
          spacing={isMobile ? "mt-4" : "mt-5"}
          size="md"
        >
          Disconnect wallet
        </DisconnectButton>
      )}
    </Wrapper>
  );
};

const TopBox = styled(Box)`
  padding: 16px 16px 16px 24px;
`;

const Wrapper = styled(Box)`
  @media ${breakpoints.mobile} {
    width: 100%;
  }
`;

const StyledBox = styled(Box)`
  display: grid;
  grid-template-rows: ${({ $compact }) => ($compact ? "69px 89px 1fr" : "94px 89px 1fr")};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-image: url(${SHAPES});
  background-position: 77%;
  background-color: ${({ theme, bgColorCard }) => theme.colors[bgColorCard]};
  background-repeat: no-repeat;
  border-radius: 12px;
  position: relative;

  @media ${breakpoints.mobile} {
    width: 100% !important;
  }
`;

const CircleCheck = styled(IconSvg)`
  path {
    fill: ${({ theme }) => theme.colors["01Primary0"]};
  }
`;

const BoxAmount = styled(Box)`
  margin-top: 4px;
  circle {
    fill: ${({ theme }) => theme.colors["02Primary0"]};
  }
  path {
    fill: ${({ theme }) => theme.colors["03Secondary800"]};
  }
`;

const BoxAddress = styled(Box)`
  background: ${({ theme, bgColor }) => theme.colors[bgColor || "03Secondary700"]};
  height: 72px;
  padding: 9px 21.91px 6px 24px;
  display: flex;
  gap: 59.91px;
  align-items: flex-start;
  & > div {
    margin: auto 0;
    word-break: break-all;
  }
  svg {
    margin-top: 21.91px;
    transform: rotate(270deg);
    cursor: pointer;
  }
`;

const Link = styled.a`
  display: flex;
  gap: 6px;
  align-items: center;
  margin-left: 24px;
  & > div {
    text-decoration: underline;
  }
`;

const CoinsImg = styled.img`
  position: absolute;
  right: -111px;
  bottom: -28px;
   @media ${breakpoints.tablet} {
    right: -63px;
    bottom: -40px;
  }
   @media ${breakpoints.mobile} {
    right: -36px;
    bottom: -42px;
    width: 129.267px;
    height: 100.564px;
  }
`;

const XMark = styled(IconSvg)`
  margin: 0 auto;
`;

WalletCard.defaultProps = {
  width: "418px",
  height: "260px",
};

export default WalletCard;
