import { Box, IconSvg, Text } from "@thrivecoin/ui-components";
import { useStoreActions } from "easy-peasy";
import styled from "styled-components";

const CONFIG = {
  banned: {
    title: "Banned",
    icon: "cancel",
  },
  restricted: {
    title: "Restricted",
    icon: "hand2",
  },
};

const TopBannerAudits = ({ user }) => {
  const { banned, restricted } = user;

  if (!banned && !restricted) {
    return <></>;
  }

  const status = banned ? "banned" : "restricted";
  const { showModal } = useStoreActions((actions) => actions.modals);
  const auditsData = CONFIG[status];

  const onClickAction = (e) => {
    const type = e.target.dataset.action;
    showModal({ modalName: "MoreDetailsModal", type, user });
  };

  return (
    <StyledBox bgColor="error" gap="12px" justifyContent="center" flex>
      <IconSvg icon={auditsData.icon} color="01Primary0" width="16px" />
      <Text typography="body/medium" color="01Primary0" inline extraBold>
        Your ThriveCoin Account has been {auditsData.title}.
        <Text onClick={onClickAction} data-action={status} spacing="pl-1" inline underline pointer>
          Click here for more details
        </Text>
      </Text>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  padding: 5.5px;
`;

export default TopBannerAudits;
