import GoBack from "@common/GoBack";
import { MainContainer } from "@common/MainContainer";
import { Box, breakpoints, TabsContainer, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import useQuery from "../hooks/useQuery";
import ListingsTab from "./ListingsTab";
import SeasonsTab from "./SeasonsTab";

import BACKGROUND_IMAGE from "@assets/season_background.svg";

const TAB_CLASSES = [SeasonsTab, ListingsTab];
const HEADERS = ["Season", "Ways to Contribute"];

const ManageTabsContainer = () => {
  const { id } = useParams();
  const selectedIndex = parseInt(useQuery().get("tabIndex")) || 0;
  const [tabIndex, setIndex] = useState(0);
  const { find } = useStoreActions((actions) => actions.community);
  const { community } = useStoreState((state) => state.community);

  const onChangeIndex = (index) => setIndex(index);

  useEffect(() => {
    find(id);
  }, [id]);

  useEffect(() => {
    if (selectedIndex) {
      setIndex(selectedIndex);
    }
  }, [selectedIndex]);

  return (
    <Box>
      <HeaderBox spacing="pt-5" flex justifyContent="space-between">
        <MainContainer>
          <GoBack
            text="Back to Manage Communities"
            textColor="01Primary700"
            isAbsolute={false}
            spacing="mb-1"
            sm-spacing="mb-5"
            navigationPath={`/manage/communities`}
          />
          <Box spacing="mb-7" flex alignItems="center" justifyContent="center" gap="16px">
            {community.logo_url && <Logo src={community.logo_url} />}
            <Text typography="heading/medium" color="01Primary700" bold>
              {community.name}
            </Text>
          </Box>
        </MainContainer>
      </HeaderBox>
      <StyledTabsContainer
        selectedIndex={tabIndex}
        tabClasses={TAB_CLASSES}
        headers={HEADERS}
        onChangeIndex={onChangeIndex}
        bgTagContainer="navigation"
        tabProps={{ communityID: id }}
      />
    </Box>
  );
};

const Logo = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;

export const HeaderBox = styled(Box)`
  background-size: cover;
  background-position-y: bottom;
  background-image: url(${BACKGROUND_IMAGE});

  @media ${breakpoints.belowDesktop} {
    flex-direction: column;
    gap: 32px;
  }
`;

const StyledTabsContainer = styled(TabsContainer)`
  & > div:first-child > div {
    & > div {
      line-height: 24px;
      max-height: 17px;
    }
  }
`;

export default ManageTabsContainer;
