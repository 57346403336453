import { Box, breakpoints, DropDown, IconSvg, Text } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useContext } from "react";
import styled from "styled-components";
import FileInput from "../../common/FileInput";
import { SeasonEditorContext } from "../SeasonEditorContext";
import { StyledInput, StyledTextArea } from "./StyledInputs";

const CustomInput = ({ id, errors, ...props }) => {
  const { isMobile } = useStoreState((state) => state.media);
  const spacing = isMobile ? "mb-5" : "mb-6";

  return (
    <StyledInput
      id={id}
      invalid={!!errors?.[id]}
      alwaysShowValidation={!!errors?.[id]}
      validationText={errors?.[id]?.[0]}
      spacing={spacing}
      {...props}
    />
  );
};

const SeasonDetails = () => {
  const { isEditing, season, errorValues, setErrorValues, updateSeason, setSeason } = useContext(SeasonEditorContext);
  const { managedCommunities } = useStoreState((state) => state.community);

  const onChange = (e) => {
    const { id, value } = e.target;
    if (errorValues?.[id]) {
      const updatedErrorValues = { ...errorValues };
      delete updatedErrorValues[id];
      setErrorValues(updatedErrorValues);
    }
    if (id && e.target.type !== "file") {
      updateSeason(id, value);
    }
  };

  const onCommunityChange = (val) => {
    updateSeason("community_id", val.id);
  };

  return (
    <Wrapper onChange={onChange}>
      <CustomInput errors={errorValues} id="title" label="Title (Required)" value={season.title} />
      <CustomInput errors={errorValues} id="subtitle" label="Subtitle (Required)" value={season.subtitle} />
      <StyledTextArea
        id="description"
        label="Description (Required)"
        rows={3}
        value={season.description}
        invalid={!!errorValues?.description}
        validationText={errorValues?.description?.[0]}
        onChange={onChange}
      />
      {!!errorValues?.description && (
        <DescriptionErrorWrapper>
          <Box flex={+true} alignItems="center" gap="8px" color="#f16d67">
            <IconSvg width="16px" icon="alert-triangle" />
            <Text>{errorValues?.description?.[0]}</Text>
          </Box>
        </DescriptionErrorWrapper>
      )}
      <Box height="1px" bgColor="01Primary200" spacing="my-6" />
      <CustomInput errors={errorValues} id="goal" label="Goal (Required)" value={season.goal} />
      <CustomInput
        errors={errorValues}
        id="order_number"
        label="Season Number (Required)"
        value={season.order_number}
      />
      <CustomInput errors={errorValues} id="label" label="Custom Label (Optional)" value={season.label} />
      {!isEditing && (
        <SectionWrapper>
          <Text bold spacing="mb-4">
            Community (Required)
          </Text>
          <DropDown
            value={season.community_id}
            valueKey="id"
            options={managedCommunities}
            label="name"
            image="logo_url"
            required
            onChange={onCommunityChange}
          />
          {!!errorValues?.community_id && (
            <Box flex alignItems="center" gap="8px" color="#f16d67">
              <IconSvg width="16px" icon="alert-triangle" />
              <Text>{errorValues.community_id}</Text>
            </Box>
          )}
        </SectionWrapper>
      )}
      <SectionWrapper>
        <FileInput
          label="Banner Image"
          subLabel="Our recommended size is 596x346px"
          name="image_url"
          media={season}
          setMedia={setSeason}
        />
      </SectionWrapper>
      <SectionWrapper>
        <FileInput
          label="Featured Image (Optional)"
          subLabel="Our recommended size is 526x660px"
          name="featured_image_url"
          media={season}
          setMedia={setSeason}
        />
      </SectionWrapper>
      <CustomInput
        id="slug"
        errors={errorValues}
        label="Slug (Required)"
        value={season.slug}
        placeholder="End of a custom URL for accessing the season. e.g. missionaries-season-1"
        spacing="mb-0"
      />
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  width: 555px;
  overflow-y: auto;

  @media ${breakpoints.tablet} {
    width: 505px;
  }

  @media ${breakpoints.mobile} {
    width: unset;
    margin: 0 16px;
  }
`;

const SectionWrapper = styled(Box)`
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors["01Primary200"]};

  @media ${breakpoints.mobile} {
    margin-bottom: 24px;
    padding-bottom: 24px;
  }
`;

const DescriptionErrorWrapper = styled(Box)`
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors["01Primary200"]};

  @media ${breakpoints.mobile} {
    margin-bottom: 24px;
    padding-bottom: 24px;
  }
`;

export default SeasonDetails;
