import { denormalize, normalize, schema } from "normalizr";

const GroupKeysToDefault = [
  "invitations",
  "category_ids",
  "membership_tag_ids",
  "membership_requests",
  "members",
  "wallets",
];

const addDefaults = (keys) => (object, parent, key) => {
  const currentKeys = Object.keys(object);
  const keysToAdd = keys.filter((key) => !currentKeys.includes(key));

  keysToAdd.forEach((key) => (object[key] = object[key] || []));

  return object;
};

const addGroupDefaults = addDefaults(GroupKeysToDefault);

const wallet = new schema.Entity("wallets", {}, { idAttribute: "address" });
const member = new schema.Entity("members", {});
const challengeStep = new schema.Entity("challengeSteps", {});
const challenge = new schema.Entity("challenges", { steps: [challengeStep] }, { idAttribute: "challenge_id" });
const invitation = new schema.Entity("invitations", {});
const contributionClaim = new schema.Entity("contribution_claims", {});
const contribution = new schema.Entity("contributions", {});
const season = new schema.Entity("seasons", {});
const listing = new schema.Entity("listings", {});
const listingseason = new schema.Entity("listing_seasons", {
  listing: listing,
  season: season,
});
const activity = new schema.Entity("activities", {});
const user = new schema.Entity("users", { wallets: [wallet], additional_wallets: [wallet] });
const group = new schema.Entity(
  "groups",
  {
    wallets: [wallet],
    members: [member],
    invitations: [invitation],
  },
  { processStrategy: addGroupDefaults }
);
const community = new schema.Entity("communities", {});
const notifications = new schema.Entity("notifications", {});
const tag = new schema.Entity("tags", {});

const ENTITIES = {
  activity,
  challenge,
  challengeStep,
  community,
  contribution,
  contributionClaim,
  group,
  invitation,
  listing,
  member,
  notifications,
  season,
  tag,
  user,
  wallet,
  listingseason,
};

export const ENTITY_NAMES = Object.keys(ENTITIES);

const normalizeEntityGEN = (entityName) => (data) => normalize(data, ENTITIES[entityName]);
const normalizeCollectionGEN = (entityName) => (data) => normalize(data, [ENTITIES[entityName]]);

const deNormalizeEntityGEN = (entityName) => (id, normalizedData) =>
  denormalize(id, ENTITIES[entityName], normalizedData);
const deNormalizeCollectionGEN = (entityName) => (ids, normalizedData) =>
  denormalize(ids, [ENTITIES[entityName]], normalizedData);

const generate = (entityName) => [
  normalizeEntityGEN(entityName),
  normalizeCollectionGEN(entityName),
  deNormalizeEntityGEN(entityName),
  deNormalizeCollectionGEN(entityName),
];

export const [normalizeWallet, normalizeWallets, denormalizeWallet, denormalizeWallets] = generate("wallet");
export const [normalizeListing, normalizeListings, denormalizeListing, denormalizeListings] = generate("listing");
export const [normalizeGroup, normalizeGroups, denormalizeGroup, denormalizeGroups] = generate("group");
export const [normalizeUser, normalizeUsers, denormalizeUser, denormalizeUsers] = generate("user");
export const [
  normalizeContributionClaim,
  normalizeContributionClaims,
  denormalizeContributionClaim,
  denormalizeContributionClaims,
] = generate("contributionClaim");
export const [normalizeContribution, normalizeContributions, denormalizeContribution, denormalizeContributions] =
  generate("contribution");

export const deNormalizeCollection = (ids, normalizedData, entityName) =>
  denormalize(ids, [ENTITIES[entityName]], normalizedData);

export const normalizeCollection2 = (data, entityName) => normalize(data, [ENTITIES[entityName]]);

export const normalizeEntity = (id, data, entityName) => normalize(id, data, [ENTITIES[entityName]]);

export const denormalizeEntity = (id, normalizedData, entityName) =>
  denormalize(id, ENTITIES[entityName], normalizedData);
