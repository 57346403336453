import { Box, SearchInput, Text } from "@thrivecoin/ui-components";
import debounce from "@utils/debounce";
import { useContext, useEffect, useMemo } from "react";
import styled from "styled-components";
import { AddListingsContext } from "./AddListingsContext";
import { IconContextMenu, SeasonListingOptions } from "./common";
import ListingsTable from "./ListingsTable";
import useSelectionColumn from "./useSelectionColumn";
const DEBOUNCE_TIME = 3000;

const cancelEvent = (event) => event.preventDefault();

const ListingDropBox = ({ onDrop, seasonListingsTotal, ...props }) => {
  useEffect(() => {
    const element = document.getElementById("listings-dropzone");
    element.ondragenter = cancelEvent;
    element.ondragover = cancelEvent;
    element.ondrop = onDrop;
  }, []);

  const Component = seasonListingsTotal ? WithListings : NoListings;

  return (
    <Box flex direction="column" gap="32px" spacing="pt-6" id="listings-dropzone">
      <Component {...props} />
    </Box>
  );
};

const NoListings = ({ title }) => (
  <BorderedBox flex gap="16px" direction="column" align="center">
    <Text typography="heading/medium" >No Ways to contribute yet on {title}</Text>
    <Text typography="body/medium" bold>
      Add your initial Way to Contribute by either clicking the button or selecting and dragging contributions from
      below to the designated placeholder here.
    </Text>
  </BorderedBox>
);

const WithListings = ({ listings, title, searchListings, previewListing, updateReward }) => {
  const { removeListingFromSeason } = useContext(AddListingsContext);
  const { selectedIDs, onSelectChange, selectAll, clearSelection, selectByStatus } =
    useSelectionColumn(".season-listings");

  const selectedCount = selectedIDs.length;
  const text =
    selectedCount > 0
      ? `${selectedCount} Selected Ways to Contribute`
      : `There are ${listings.length} Ways to Contribute in ${title}`;

  const removeFromSeason = (e) => {
    const id = e.target.dataset.id;
    removeListingFromSeason(id);
  };

  const doSearch = useMemo(() => {
    return debounce(searchListings, DEBOUNCE_TIME);
  }, []);

  const onClear = () => searchListings("");

  const removeSelected = (e) => {
    removeListingFromSeason(selectedIDs).then(clearSelection);
  };

  const listingProps = {
    OptionsComponent: SeasonListingOptions,
    seasonName: title,
    removeFromSeason,
    updateReward,
    previewListing,
  };

  return (
    <Box onChange={onSelectChange}>
      <Box flex gap="16px" alignItems="center">
        <Text typography="Heading/Small">{text}</Text>
        {selectedIDs.length > 0 && (
          <IconContextMenu>
            <ul>
              <li onClick={clearSelection}>Clear Selection</li>
              <li onClick={removeSelected}>Remove from {title}</li>
            </ul>
          </IconContextMenu>
        )}
        <SearchInput spacing="ml-a" onChange={doSearch} onClear={onClear} />
      </Box>
      <ListingsTable
        showStatus
        showRewards
        selectAll={selectAll}
        listings={listings}
        listingProps={listingProps}
        selectedIDs={selectedIDs}
        className="season-listings"
      >
        <ul>
          <li onClick={selectAll}>All</li>
          <li onClick={clearSelection}>None</li>
          <li onClick={selectByStatus} data-status="pending">
            Pending
          </li>
          <li onClick={selectByStatus} data-status="active">
            Active
          </li>
        </ul>
      </ListingsTable>
    </Box>
  );
};

IconContextMenu;

const BorderedBox = styled(Box)`
  border: 1px dashed ${({ theme }) => theme.colors["01Primary400"]};
  border-radius: 24px;
  padding: 70px 114px;
`;

ListingDropBox.defaultProps = {
  listings: [],
  onDrop: () => {},
};

export default ListingDropBox;
