import { useState } from "react";

const useSelectionColumn = (containerSelector) => {
  const [selectedIDs, setSelected] = useState([]);

  const onSelectChange = (event) => {
    const { checked } = event.target;
    const id = parseInt(event.target.dataset.id);
    const selection = checked ? [...selectedIDs, id] : selectedIDs.filter((x) => x != id);
    setSelected(selection);
  };

  const selectAll = (markAll = true) => {
    const selector = `${containerSelector}  tr[data-id]`;
    const ids = markAll
      ? Array.from(document.querySelectorAll(selector)).map(({ dataset }) => parseInt(dataset.id))
      : [];
    setSelected(ids);
  };

  const selectByStatus = (event) => {
    const { status } = event.currentTarget.dataset;
    const selector = `${containerSelector}  tr[data-status='${status}']`;
    const ids = Array.from(document.querySelectorAll(selector)).map(({ dataset }) => parseInt(dataset.id));
    setSelected(ids);
  };

  const clearSelection = (e) => setSelected([]);

  return { selectedIDs, onSelectChange, selectAll, selectByStatus, clearSelection };
};

export default useSelectionColumn;
