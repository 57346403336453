import { breakpoints } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import styled from "styled-components";
import { TableOfContributions } from "../common";
import ContributionsHeaderRow from "./ContributionsHeaderRow";
import ContributionsRow, { Row } from "./ContributionsRow";
import LoaderTable from "./LoaderTable";

const TableContributions = styled(
  ({
    data,
    onCommunityClick,
    onSort,
    sortType,
    sortDirection,
    loading,
    isSearching,
    onProfileClick,
    hide_amounts,
    isCustomToken,
    showCommunity,
    isFromProfile,
    className,
  }) => {
    const { isDesktop, isTablet, isMobile } = useStoreState((state) => state.media);
    const fromProfileTablet = isTablet && isFromProfile;
    const marginTop = fromProfileTablet ? "32px" : "40px";
    const TableComponent = isFromProfile ? ProfileTable : TableOfContributions;

    return (
      <>
        <TableComponent
          padding={isFromProfile && isMobile && "0"}
          marginTop={isFromProfile && marginTop}
          className={className}
        >
          {!isMobile && (
            <ContributionsHeaderRow
              onSort={onSort}
              sortType={sortType}
              sortDirection={sortDirection}
              hide_amounts={hide_amounts}
              isCustomToken={isCustomToken}
              isDesktop={isDesktop}
              isTablet={isTablet}
              isMobile={isMobile}
              showCommunity={showCommunity}
              isFromProfile={isFromProfile}
              fromProfileTablet={fromProfileTablet}
            />
          )}
          {!loading && (
            <tbody>
              {data?.map((item) => (
                <ContributionsRow
                  key={item.id}
                  {...item}
                  hide_amounts={hide_amounts}
                  onCommunityClick={onCommunityClick}
                  isSearching={isSearching}
                  onProfileClick={onProfileClick}
                  isCustomToken={isCustomToken}
                  isDesktop={isDesktop}
                  isTablet={isTablet}
                  isMobile={isMobile}
                  showCommunity={showCommunity}
                  isFromProfile={isFromProfile}
                  fromProfileTablet={fromProfileTablet}
                />
              ))}
            </tbody>
          )}
        </TableComponent>
        <LoaderTable loading={loading} />
      </>
    );
  }
)`
  th,
  td {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 24px;
  }

  tr td:last-child {
    padding-right: 24px;
  }

  && {
    .order_number a {
      color: ${({ theme }) => theme.colors["01Secondary300"]};
      font-weight 700;
    }

    @media ${breakpoints.tablet} {
      th,
      td {
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 16px;
      }

      tr td:last-child {
        padding-right: 16px;
      }
    }

    @media ${breakpoints.mobile} {
      th,
      td {
        padding: 0;
      }
    }
  }
`;

const ProfileTable = styled(TableOfContributions)`
  @media ${breakpoints.mobile} {
    ${Row} {
      grid-template-areas:
        "a  b "
        "a2 b2"
        "a3 b3";

      .community,
      .order_number,
      .date {
        text-align: end;
      }

      .contribution {
        grid-area: a;
      }

      .rewards {
        grid-area: a2 / a3;
      }

      .community {
        grid-area: b;
      }

      .order_number {
        grid-area: b2;
      }

      .date {
        grid-area: b3;
      }
    }
  }
`;

export default TableContributions;
